.header-logo {
  display: inline-block;
  width: 120px;
  height: 100%;
  vertical-align: super;
  cursor: pointer;
}
.datafooter {
  background: #f6f6f6;
  color: #d1d1d1;
  padding: 0px 30px;
  border-top:15px;
  margin-top:15px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-select{
    width:230px;
    height:20px;
    background: #ffffff;
    color: #000000;
    font-size: 8px;
    border-radius: 0px;
    border: solid 1px #dddddd;
    @media (max-width: 1500px) {
      width:160px;
      //   margin: 'auto'
    }
  }
  .footer-map {
    width:78%;
    height:55px;
    text-align: center;
    padding-top:5px
  }
  .footer-selectdiv{
    width:15%;
    text-align: center;
  }
  .footer-contact{
    width:7%;
    font-size: 8px;
    color: #d1d1d1;
    cursor: pointer;
    text-decoration: underline;
  }
  .footer-logo {
    width:20%;
    // height:55px;
    text-align: center;
    padding-top:15px;
    display: flex;
    float: left;
  }
  .footer-addr {
    margin-top: 20px;
  }
  .footer-accent {
    padding-top:  5px;
    text-align: center; 
    font-size: 11px;
    line-height: 1.3;
  }
  .footer-release{
    font-size: 8px;
    // padding-top: 6px;
    color:#8a8a8a;
  }
  .footer-icon{
    font-size: 10px;
  }
  .footer-name{
    padding: 0px 9px;
  }
  .footer-line{
    width: 1px;
    height: 41px;
     border-left: solid 1px #d1d1d1;
  }
  .footer-selectmenu {
    font-size: 10px ;
    min-height: 20px ;
    padding: 0px;
  }
}
.footer {
  background: #383838;
  color: #d1d1d1;
  padding: 2.5px 20px;
  // text-align: center;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-select{
    width:250px;
    height:20px;
    background: #ffffff;
    color: #000000;
    font-size: 8px;
    border-radius: 0px;
    border: solid 1px #dddddd;
    @media (max-width: 1500px) {
      width:160px;
      //   margin: 'auto'
    }
  }
  .footer-map {
    width:60%;
    height:55px;
    text-align: center;
    // padding-top:15px
  }
  .footer-selectdiv{
    width:15%;
    text-align: center;
  }
  .footer-name{
    padding: 0px 9px;
  }
  .footer-contact{
    width:5%;
    font-size: 8px;
    color: #d1d1d1;
    cursor: pointer;
    text-decoration: underline;
  }
  .footer-logo {
    width:20%;
    // height:55px;
    text-align: center;
    padding-right:20px;
    // padding-top:15px;
    display: flex;
    float: left;
  }
  .footer-addr {
    margin-top: 20px;
    display: flex;
    @media (max-width: 1800px) {
      display:block;
    }
    }
  .footer-accent {
    // padding-top:  5px ;
    text-align: center; 
    font-size: 13px;
  }
  .footer-release{
    font-size: 8px;
    // padding-top: 6px;
    color:#8a8a8a;
  }
  .footer-line{
    width: 1px;
    height: 41px;
     border-left: solid 1px #d1d1d1;
  }
  .footer-icon{
    font-size: 11px;
  }
  .footer-selectmenu {
    font-size: 10px !important;
    min-height: 0px !important;
    padding: 0px;
  }
}

//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800");
//@import '~angular-tree-component/dist/angular-tree-component.css';
$kmp-dark: #343a40;
$kmp-green: #09b894;

@media (min-width: 1200px) {
  .col-lg-4,
  .col-lg-8 {
    height: calc((100vw / 3) - 6vw);
  }
  .half-col-md {
    height: calc((100vw / 8) + 0.8vw);
  }
  .half-col-md:nth-child(2) {
    margin-bottom: 10px;
  }
  // ngx-charts style
  .chart-legend {
    position: absolute;
    right: 30px;
  }
  .half-container-ngx-graph {
    width: 100%;
    height: 180px;
  }
}
//@media (min-width: 1200px) and (max-width: 1280px) {
//  .graph-container {
//    margin-top: 10px;
//
//  }
//  .ngx-charts-guide-line { width: 84.5% !important; }
//}

.graph-container {
  margin-top: 10px;
}

.ngx-charts-guide-line {
  width: 84.5% !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ngx-charts-guide-line {
    width: 89.5% !important;
  }
}

// ******************** common style ********************
html,
body {
  // margin: 0;
  font-family:"Roboto", "Arial", "Helvetica", sans-serif !important;
}
body .row {
  margin: 0;
}
[class*="col-"] {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
//.merge-height { height: 583px; }
//.merge-height-half { height: 280px; }
//.col-lg-4 { height: calc((100vw / 3) - 3vw) !important }
//[class*="col-"]:first-child { padding-left: 0 !important; }
//[class*="col-"]:last-child { padding-right: 0 !important; }
.no-outline {
  outline: none !important;
}
.no-border {
  border: none !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-right-margin {
  margin-right: 0 !important;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-top-50 {
  margin-top: 40px;
}
.margin-top-minus-50 {
  margin-top: -50px;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.common-border-radius-0 {
  border-radius: 0 !important;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
svg.ng-star-inserted {
  vertical-align: middle !important;
}

// side nav ****************************************************************
.mat-expansion-indicator::after {
  margin-bottom: 8px;
  color: #575252;
}
p.before-filtering-navigation-title:focus {
  outline: none;
}
mat-drawer.side-nav {
  font-family: "Open Sans", sans-serif;
  background: white;
  border-top: 1px solid #717171;
  border-right: 2px solid #ddd;
  padding: 0;
  margin: -1px 0;
  /**
  before filtering view(sidenav)
   */
  .before-filtering {
    font-family: "Open Sans", sans-serif;
    padding-left: 0;
    padding-right: 0;
    .overview-navigator {
      width: 100%;
      padding: 14px 0;
      text-align: center;
      background: #bbb;
      color: #fff;
      border-bottom: 1px solid #fff;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.05rem;
    }
    ul.nav-tabs {
      border-bottom: none;
    }
    .nav-tabs {
      border-bottom: 1px solid #ececec;
      li {
        margin-bottom: 0;
        display: inline-block;
        border-right: 1px solid #ddd;
        width: 33.3%;
        padding: 0.55rem 0;
      }
      li:last-child {
        border-right: none;
      }
      &-full {
        width: 100% !important;
        a {
          text-align: center;
          padding: 10px 50px;
        }
      }
    }
    .nav-tabs > li > a {
      text-align: center;
      color: #bfbfbf;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.04rem;
      border: 0;
      border-radius: 0;
      padding: 0.8rem 0;
      margin-right: 0;
    }
    .nav-tabs > li.active,
    .nav-tabs > li.active:hover,
    .nav-tabs > li.active a:hover,
    .nav-tabs > li.active a {
      background: #bbbbbb;
      color: white;
    }
    .nav > li > a:hover {
      background: white;
    }
    .sidenav-toggle-btn-left {
      position: absolute;
      top: 68px;
      right: 23px;
      padding: 4px 10px;
      background-color: #ffffff00;
      border-color: gray;
    }
    .tab-pane form {
      padding: 1rem 2rem 1.7rem;
    }
    .tab-pane form.side-nav-input {
      padding: 0 0 2rem;
    }
    .tab-pane form.side-nav-input-with-checkbox {
      padding: 0.5rem 0 0 0;
    }
    .form-group,
    .input-group {
      width: 100%;
    }
    .form-control {
      height: 35px;
      font-size: 1.4rem;
    }
    .form-control::placeholder {
      color: #d5d5d5;
      font-size: 1.3rem;
    }
    .before-filtering-title {
      margin-top: 35px;
      font-size: 20px;
    }
    .before-filtering-figure {
      font-size: 16px;
      margin-right: 5px;
    }
    .checkbox {
      label {
        font-size: 16px;
        font-weight: 400;
        margin-left: 5px;
      }
      &-title {
        margin-left: 0;
        padding-left: 0;
      }
      span.label-default {
        float: right;
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-top: 0.3rem;
        padding: 0.3rem 1.2rem;
        background: #6a706f;
        border-radius: 10px;
      }
    }
    .radio-inline.radio-first {
      margin-left: 5px;
    }
    .radio-inline {
      font-size: 16px;
    }
    .from-to-go-search {
      margin-top: 10px;
      .input-group-addon,
      .form-control {
        border-right: none;
      }
      .input-group-addon:last-child {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        cursor: pointer;
        backgroun-color: #30383b;
      }
      .input-group-addon {
        background-color: #404040;
        color: white;
      }
    }
    #genes {
      padding-bottom: 20px;
    }
    #mutations {
      padding-bottom: 20px;
    }
  }

  .admin-sidenav {
    &-menu {
      padding: 15.5px 40px;
      margin: 0;
      font-weight: 600;
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.86) !important;
      background: white !important;
    }

    // active sidenav menu style *****
    &-menu-active,
    &-menu.active,
    &-menu-active.active {
      color: white !important;
      background: #485465 !important;
    }
    &-menu-active {
      .mat-expansion-panel-header-title {
        color: white;
      }
    }
    &-menu-active:focus {
      outline: none;
    }
    p.before-filtering-navigation-title.active {
      background: #989898;
      color: white;
      outline: none !important;
    }
    p.before-filtering-navigation-title.active:focus {
      outline: none !important;
    }
    // active sidenav menu style *****

    .mat-expansion-panel-header {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
    .mat-expansion-panel-body {
      font-family: "Open Sans", sans-serif;
      padding: 0;
      background: #f7f7f7;
      p {
        margin: 0;
        padding: 12px 40px;
        font-size: 13px;
        color: #5e5c5c;
      }
    }
  }
}
mat-drawer-content.mat-drawer-content .after-filtering {
  padding-left: 0;
  padding-right: 0;
}
.after-filtering {
  .common-button-with-tab-nav {
    display: inline-block;
    width: 100%;
    height: 42px;
    background: white;
    border-bottom: 2px solid #dddddd;
    .sidenav-toggle-btn-right {
      position: absolute;
      top: 68px;
      padding: 4px 10px;
    }
    .common-side-nav-toggle-left {
      display: inline-block;
      width: 33px;
      height: 42px;
      background: #09b894;
      font-size: 1rem;
      color: white;
      border-radius: 0;
      border: none;
      vertical-align: top;
    }
    .common-side-nav-toggle-left:active,
    .common-side-nav-toggle-left:focus {
      background: #09b894 !important;
      color: white !important;
      border-color: unset !important;
      box-shadow: none !important;
    }
    .nav-tabs {
      display: inline-block;
      height: 100%;
      border-bottom: none;
      vertical-align: middle;
      padding-left: 62px;
    }
    .nav-tabs li a {
      border-radius: 0;
      border: none;
      padding: 20px 35px;
      margin: 0;
    }
    .nav-tabs li.active a,
    .nav-tabs li.active a:hover,
    .nav-tabs li.active a:focus {
      border: none;
      border-bottom: 6px solid #09b894 !important;
      color: #5f5f5f;
    }
    .nav-tabs li,
    .nav-tabs li a {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      color: #bfbfbf;
      height: 100%;
    }
    .save-query {
      display: inline-block;
      float: right;
      padding: 12px 0;
    }
    .save-query button {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
    }
    span {
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      .common-radius-btn {
        padding: 5px 22px;
      }
    }
    .search-conditions {
      span {
        text-align: left;
      }
    }
  }
  .common-data-info-nav {
    display: inline-block;
    width: 100%;
    height: 230px;
    background: white;
    border-bottom: 2px solid #dddddd;
  }
  .side-nav-data-filter {
    background: #fff;
    display: inline-block;
    &-container {
      display: inline-block;
      padding: 6px;
    }
    &-circle {
      display: inline-block;
      background: #e2e2e2;
      border-radius: 2rem;
      padding-right: 15px;
      .side-nav-data-filter-white-arrow {
        display: inline-block;
        background: #fff;
        margin: 4px 5px 4px 4px;
        padding: 0 0.5rem;
        border-radius: 1rem;
        i {
          color: #e2e2e2;
          font-size: 1rem;
        }
      }
      .side-nav-data-filter-category {
        color: #636363;
        font-size: 1rem;
      }
      .side-nav-data-filter-between-arrow {
        color: #ababab;
        margin: 0 0.4rem;
        font-size: 1rem;
      }
      .side-nav-data-filter-value {
        display: inline-block;
        color: #636363;
        font-size: 1rem;
      }
    }
  }
}

.select-service {
  display: inline-block;
  float: right;
  margin-top: -40px;
  margin-right: 30px;
  .ui-dropdown {
    width: 163px !important;
    border-radius: 0;
  }
  .ui-dropdown .ui-dropdown-trigger {
    width: 30px;
  }
  .ui-dropdown .ui-dropdown-clear-icon {
    right: 3.5em;
  }
  .ui-dropdown-label.ui-placeholder {
    font-size: 10px;
    padding: 6.5px 40px 6.5px 10px;
  }
  span {
    text-align: center;
    vertical-align: middle;
  }
}

.common-before-filtering-tab {
  .nav-tabs {
    margin-left: 110px;
    margin-top: -43px;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 5px solid #09b894 !important;
    padding: 10px 15px !important;
    color: #5f5f5f !important;
  }
  .nav-tabs > li > a {
    border: none;
    font-size: 13px;
    color: #bfbfbf;
  }
  .nav-tabs > li > a:hover {
    background: none;
  }
}
mat-drawer-container.mat-drawer-container {
  overflow: unset;
}
mat-drawer-content.mat-drawer-content {
  //min-height: calc(100vh - 6rem);
  min-height: 178vh;
  background: #f6f6f7;
  padding-bottom: 1rem;
  overflow: hidden;
}
mat-expansion-panel.mat-expansion-panel {
  box-shadow: none !important;
  border-bottom: 2px solid #ddd;
  //mat-expansion-panel-header { padding: 0 62px 0 46px; }
  mat-expansion-panel-header span {
    font-family: "Open Sans", sans-serif !important;
    font-size: 1.4rem;
    font-weight: 600;
  }
  .mat-expansion-panel-content .mat-expansion-panel-body .checkbox label {
    //width: 130px;
    //min-width:12rem;
    //max-width:21rem;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mat-expansion-panel-body {
    padding: 0 20px;
  }
}
mat-expansion-panel.mat-expansion-panel:last-child {
  border-bottom: none;
}
.mat-expanded.mat-expansion-panel-spacing {
  margin: 0;
}
// side nav ****************************************************************

// radio button ***************************************
.radio-item {
  display: inline-block;
  position: relative;
}
// .radio-item input[type='radio'] { display: none; }
.radio-item label {
  color: #666;
  font-weight: normal;
}
.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #595959;
  background-color: transparent;
}
.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: #5f5f5f;
}

input[type="radio"] + label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 13px;
  height: 13px;
  padding: 3px;
  margin-right: 5px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: unset;
  border-radius: 50%;
  vertical-align: bottom;
}
input[type="radio"]:checked + label:before {
  background-color: #09b894;
}
// input[type="radio"] {
//   display: none;
// }
.radioButton {
  padding: 0.3rem 2rem;
  border-bottom: 1px solid #f4f4f4;
}
.radioButton label {
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}
.radioButton .label-default {
  float: right;
  margin-top: 4px;
  border-radius: 10px;
  padding: 2px 10px;
}

// data-explorer reported select radio button --
.reported {
  display: inline-block;
  padding: 0;
  margin-right: 10px;
}
.reported:last-child {
  margin: 0;
}
.reported:first-child {
  padding: 0;
}
// data-explorer reported select radio button --
// radio button ***************************************

// check box *********************************************
.checkbox:first-child {
  padding-top: 1rem;
}
.checkbox {
  text-align: left;
  margin: 0 !important;
  padding: 0.3rem 1.5rem;
}
.checkbox label:after {
  content: "";
  display: table;
  clear: both;
}
.checkbox label {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif !important;
  font-size: 15px !important;
  color: #000000;
  padding: 0 !important;
  min-height: unset !important;
  display: inline-block;
  span:nth-child(3) {
    font-size: 1rem !important;
    margin-left: -0.2rem !important;
  }
}
.checkbox label:nth-child(2) {
  font-size: 1rem !important;
  margin-left: -0.2rem !important;
}
.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #000000;
  border-radius: 0;
  width: 1.3em;
  height: 1.3em;
  float: left !important;
  margin-right: 0.5em;
  padding: 0 !important;
}
.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 10px !important;
  line-height: 0;
  top: 50%;
  left: 15%;
}
.checkbox label input[type="checkbox"] {
  display: none;
}
.checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
}
.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  opacity: 1;
}
.checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}
// check box *********************************************

// input **************************************************
.input-group input {
  font-family: "Open Sans", sans-serif;
  border-top: 1px solid #616161;
  border-left: 1px solid #616161;
  border-bottom: 1px solid #616161;
}
.input-group input.input-group-input-border-right {
  border-right: 1px solid #616161;
}
.input-group-addon {
  font-family: "Open Sans", sans-serif;
  font-weight: 300 !important;
  background: #a1a1a1 !important;
  color: white !important;
  border: 1px solid #a1a1a1 !important;
}

.common-input-group-addon-white {
  background: white !important;
  border: none !important;
  color: #595959 !important;
  padding: 6px 5px !important;
}

.input-required {
  color: #ff4343;
}
.input-lg {
  border-radius: 0 !important;
  margin-bottom: 9px;
}
.common-input-group {
  margin-bottom: 0 !important;
  border-bottom: none !important;
}
.form-control::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
} /* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
} /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
} /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
} /* Internet Explorer 10-11 */
.form-control:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
} /* Microsoft Edge */
.common-form-title {
  margin-bottom: 30px;
  text-align: center;
  &-bold {
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    margin: 0;
  }
  &-small {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #888888;
  }
}
// input **************************************************

// button **************************************************
.common-radius-btn {
  font-family: "Open Sans", sans-serif;
  border-radius: 17px !important;
  border: 1px solid #a6a6a6 !important;
  color: #a6a6a6 !important;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.common-radius-btn:hover {
  background-color: #09b894 !important;
  border: 1px solid #09b894 !important;
  color: white !important;
}
.btn.common-btn-default,
.btn.common-btn-green,
.btn.common-btn-red {
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
}
.btn.common-btn-default,
.btn.common-btn-default:hover,
.btn.common-btn-default:active,
.btn.common-btn-default:focus,
.btn.common-btn-default.none {
  background: none !important;
  box-shadow: none !important;
  color: #000;
}
.btn.common-btn-green,
.btn.common-btn-green:hover,
.btn.common-btn-green:active,
.btn.common-btn-green:focus,
.btn.common-btn-default.active {
  border: 1px solid #09b894 !important;
  background: #09b894 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.btn.common-btn-red,
.btn.common-btn-red:hover,
.btn.common-btn-red:active,
.btn.common-btn-red:focus {
  border: 1px solid #e94d4d !important;
  background: #e94d4d !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
// button **************************************************

// display type ***************************************************
.display-table {
  display: table;
  background-color: #ffffff;
  width: 100%;
  //min-height: calc(100vh - 89.69px - 126px);
  // min-height: calc(100vh - 184px);
  min-height: 90vh;
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
// display type ***************************************************
// ******************** common style ********************

// ******************** primeNG - button common style ********************
.ui-paginator .ui-paginator-page.ui-state-active {
  color: #6a6a6a !important;
  border: 1px solid #cfcfcf !important;
  background: white !important;
  border-radius: 0;
}
.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
  background: white !important;
}

.ui-paginator .ui-dropdown {
  border-radius: 0;
  padding: 2px 5px;
}
.ui-dropdown:not(.ui-state-disabled):hover {
  background: none !important;
}

.ui-chkbox .ui-chkbox-box {
  height: 1.14em !important;
}
.ui-widget.ui-button,
.ui-button {
  border: 1px solid #343a40 !important;
  color: white !important;
  background: #343a40 !important;
}
.ui-datatable
  table
  button.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-text-empty.ng-star-inserted {
  background: none !important;
  color: #979797 !important;
  border: 1px solid #979797 !important;
  font-family: "Open Sans", sans-serif !important;
  padding: 3px 5px;
}
.ui-datatable
  table
  button.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-text-empty.ng-star-inserted:hover {
  background: #09b894 !important;
  color: white !important;
  border: 1px solid #09b894 !important;
}
.datatable-column-icon-material {
  vertical-align: bottom;
  font-size: 20px;
}
// ******************** primeNG - button common style ********************

// **************** after filtering content common style ****************
// ********** card view **********
.card-view {
  &-title {
    border-bottom: 1px solid #dddddd;
    padding: 13px 25px;
    margin: 0;
    color: #707070;
    font-size: 15px;
    &-right {
      float: right;
    }
  }
  // **************** system dashboard style card view ********************************
  &-content-text {
    padding: 43.7px 0 43.7px;
    p {
      display: inline-block;
      margin: 0;
    }
    &-usage {
      font-size: 12px;
      color: #a6a6a6;
      float: left;
      margin: 0;
    }
    &-percent {
      font-size: 2.5rem;
      color: #09b894;
      margin: 0;
    }
    &-percent-unit {
      font-size: 12px;
      color: #a6a6a6;
    }
  }
  &-content {
    padding: 44px 23px;
    &-btn {
      display: inline-block;
      width: 44%;
      vertical-align: middle;
      padding-right: 50px;
      //margin-right: 50px;
      .btn-success-green,
      .btn-fail-default,
      .btn-success-default,
      .btn-fail-red {
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
      }
      .btn-success-green,
      .btn-success-default {
        width: 100%;
        padding: 11px 0;
      }
      .btn-fail-default,
      .btn-fail-red {
        width: 100%;
        padding: 11px 0;
      }
      .btn-success-green,
      .btn-success-green:hover,
      .btn-success-green:focus {
        background: #09b894 !important;
        color: white !important;
      }
      .btn-fail-red,
      .btn-fail-red:hover,
      .btn-fail-red:focus {
        background: #e94d4d !important;
        color: white !important;
      }
    }
    &-summary {
      display: inline-block;
      vertical-align: middle;
      .margin-bottom-3 p {
        margin-bottom: 3px;
      }
      &-left-text,
      &-right-text {
        display: inline-block;
        text-align: left;
        font-size: 15px;
      }
      &-left-text {
        padding-right: 11px;
        color: #a6a6a6;
      }
      &-right-text {
        color: #474747;
      }
    }
  }
  &-content-progress {
    width: 60%;
    margin: 20px auto 0px;
    &-summary {
      padding: 5px 36px 20px;
      p {
        .card-view-content-left-text {
          font-size: 12px;
          color: #a6a6a6;
        }
        .card-view-content-right-text {
          font-size: 12px;
          color: #474747;
        }
      }
    }
  }
  // **************** system dashboard style card view ********************************

  /*system service management style card view*/
  &-status {
    p {
      font-size: 2.5rem;
      color: #09b894;
      margin-bottom: 0;
      padding: 35px 0;
    }
  }
  &-summary {
    text-align: left;
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    &-title {
      font-size: 12px;
      color: #a6a6a6;
    }
    &-description {
      float: right;
      font-size: 12px;
      color: #474747;
    }
  }
  &-control-btn {
    .btn-success-green,
    .btn-fail-default,
    .btn-success-default,
    .btn-fail-red {
      border-radius: 0;
    }
    .btn-success-green,
    .btn-success-green:hover,
    .btn-success-green:active,
    .btn-success-green:focus {
      width: 81%;
      //padding: 9px 70px;
      padding: 9px 0;
      margin-top: 30px;
      border-color: #09b894 !important;
      background: #09b894 !important;
      color: white !important;
      box-shadow: none;
    }
    .btn-fail-red,
    .btn-fail-red:hover,
    .btn-fail-red:active,
    .btn-fail-red:focus {
      width: 81%;
      //padding: 9px 73px;
      padding: 9px 0;
      margin: 10px 0 20px;
      border-color: #ff5757 !important;
      background: #ff5757 !important;
      color: white !important;
      box-shadow: none;
    }
    .btn-fail-default,
    .btn-fail-default:hover,
    .btn-fail-default:active,
    .btn-fail-default:focus {
      width: 81%;
      //padding: 9px 73px;
      padding: 9px 0;
      margin: 10px 0 20px;
      background: white !important;
      box-shadow: none;
    }
    .btn-success-default,
    .btn-success-default:hover,
    .btn-success-default:active,
    .btn-success-default:focus {
      width: 81%;
      //padding: 9px 70px;
      padding: 9px 0;
      margin-top: 30px;
      background: white !important;
      box-shadow: none;
    }
  }
  /*system service management style card view*/
}
// ********** card view **********

.form-control.service-select {
  float: right;
  width: 160px;
}
.span-underline {
  text-decoration: underline;
}
.dataTable-column-link {
  color: #2fa7d4;
  font-size: 13px;
  text-decoration: underline;
  i {
    padding-left: 5px;
  }
}
.dataTable-column-link.ng-star-inserted,
.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dataTable-column-btn {
  padding: 6px 20px !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: initial !important;
  border-radius: 0 !important;
  border: 1px solid #a6a6a6 !important;
  color: #a6a6a6 !important;
}
.dataTable-column-btn:hover {
  background: #09b894 !important;
  border: 1px solid #09b894 !important;
  color: white !important;
}
.dataTable-column-btn[disabled]:hover {
  background: white !important;
  border: 1px solid #a6a6a6 !important;
  color: #a6a6a6 !important;
}

// ********** search form **********
.after-filtering-content-in-tab-searchForm {
  float: right;
  margin-top: 10px;
}
.periodSearchForm {
  float: right;
  margin-bottom: 15px;
  label {
    font-size: 16px;
    font-weight: 400;
    vertical-align: bottom;
    margin-right: 10px;
  }
  input.form-control {
    width: 270px;
  }
}
.onlySearchForm {
  margin-top: 0 !important;
  margin-bottom: 15px;
}
.textWithSearchForm {
  margin-top: 22px !important;
}
// ********** search form **********

.after-filtering-content {
  .ui-datatable-tablewrapper {
    margin-top: 15px;
  }
  .label-default {
    background-color: #343a40;
    font-size: 14px;
    font-weight: normal;
    margin-left: 10px;
  }
  &-title {
    font-size: 22px;
    font-weight: 600;
  }
  &-title-description {
    font-size: 16px;
  }
  &-title-amount {
    float: left;
    padding-top: 10px;
    font-size: 18px;
  }
  &-button {
    float: right;
    margin-bottom: 10px;
    #add-btn {
      font-size: 16px;
      padding: 3px 8px;
    }
    #modify-btn,
    #delete-btn {
      font-size: 16px;
      padding: 3px 5px;
    }
  }
}

.inline-group-of-slice {
  padding: 1rem 0.5rem 0;
  text-align: center;
  .slice-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
  }
  .add-type-basic {
    padding: 50px 60px 40px;
    text-align: left;

    span.title {
      font-size: 22px;
      font-weight: 600;
    }
    p.title-description {
      font-size: 16px;
    }
    .title-button {
      margin-top: 30px;
      .column-btn {
        background-color: #e0e0e0;
        color: #5e5e5e;
        padding: 10px 23px;
      }
    }
    form.table-title-search-form {
      float: right;
      //margin-top: 10px;
    }

    // full size table
    .header {
      width: 100%;
      display: inline-block;
      color: #474747;
      text-align: left;
      .header-title {
        font-size: 23px;
        margin: 0;
        display: inline-block;
      }
      .header-title.one-line-table-header-title {
        padding-bottom: 25px;
      }
      .header-title-description {
        font-size: 17px;
        margin-bottom: 18px;
      }
      .header-title-link {
        color: #29bfff;
        text-decoration: underline;
        margin-right: 4rem;
      }
      .header-title-link:last-child {
        margin-right: 0;
      }
    }

    .header-btn {
      display: inline-block;
      float: right;
      padding-top: 20px;
      .common-btn-default,
      .common-btn-green {
        font-size: 15px;
        padding: 8px 15px;
      }
    }
    .header-btn.one-line-header-btn {
      padding-top: 15px;
    }
    .header-graph-btn {
      display: inline-block;
      float: right;
      margin-top: 10px;
    }
    .header-column-btn {
      background-color: #e0e0e0;
      color: #5e5e5e;
      padding: 10px 23px;
    }
    .header-selector {
      text-align: right;
      float: right;
      margin-top: 10px;
      span {
        text-align: center;
      }
    }
    .excel-download::after {
      content: "";
      display: table;
      clear: both;
    }
    .excel-download {
      float: right;
      &-btn {
        margin-bottom: 10px;
        font-size: 12px;
        i {
          font-size: 14px;
          vertical-align: sub;
        }
      }
      &-btn:hover,
      &-btn:active {
        border: 1px solid #09b894;
        background: #09b894 !important;
        color: white;
        font-weight: 500;
        outline: none;
      }
    }
    .progress-with-graph {
      display: inline-block;
      width: 100%;
      .content-progress {
        width: 15%;
        display: inline-block;
        vertical-align: middle !important;
      }
      .content-progress-description {
        width: 12%;
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        &-rect {
          margin-bottom: 15px;
          &-green,
          &-orange,
          &-red {
            display: inline-block;
            width: 12px;
            height: 12px;
          }
          &-green {
            background: #09b894;
          }
          &-orange {
            background: #ff9722;
          }
          &-red {
            background: #f24d4d;
          }
          span {
            padding-left: 10px;
            font-size: 12px;
            color: #474747;
          }
        }
        &-rect:last-child {
          margin-bottom: 0;
        }
      }
      .content-graph {
        width: 71%;
        min-height: 200px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .header-tooltip {
      position: relative;
      display: inline-block;
    }
    .header-tooltip .tooltip-div {
      visibility: hidden;
      width: 500px;
      font-size: 1.2rem;
      padding: 10px 20px 20px;
      position: absolute;
      top: 44px;
      z-index: 1;
      color: #555;
      background-color: #fff;
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
    }
    .header-tooltip .tooltip-div::after {
      content: "";
      position: absolute;
      left: 20px;
      top: -5px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 5px solid black;
      border-color: transparent transparent #fff #fff;
      transform: rotate(135deg);
      box-shadow: -4px 3px 10px 0 rgba(0, 0, 0, 0.3);
    }
    .header-tooltip:hover .tooltip-div {
      visibility: visible;
    }
  }

  .add-type-graph {
    // default type
    .header {
      padding-bottom: 20px;
    }
    .header-title {
      float: left;
      font-weight: 600;
      color: #636363;
      display: inline-block;
      margin: 0;
    }
    .header-counting-label {
      font-weight: bold;
    }
    .header-label-rect {
      display: inline-block;
      background: #f9f9f9;
      border: 1px solid #e4e4e4;
      padding: 2px 15px;
      margin-left: 2px;
      font-size: 1.2rem;
      span {
        color: #636363;
      }
    }
    .card-type-header {
      text-align: right;
      font-size: 1.2rem;
      border-bottom: 1px solid #bababa;
      padding: 0.5rem 1.2rem;
    }
    .graph-guide {
      font-size: 1.2rem;
      float: left;
      padding-top: 0.5rem;
      padding-left: 1.3rem;
    }
    .graph-title {
      width: 90%;
      padding: 10px 0 5px 0;
      margin: 0 auto;
      text-align: left;
      font-size: 1rem;
      .right-title {
        float: right;
      }
    }
    .graph-container {
      display: grid;
      width: 100%;
      height: 100%;
      //min-height: 220px;
      //max-height: 220px;
    }
    .graph-container.one-over-four-big {
      display: grid;
      width: 100%;
      height: 100%;
      min-height: 530px;
      .ngx-charts-outer {
        margin: 0 auto;
      }
    }
    .graph-container.one-over-four-big.small-pie-graph {
      min-height: 270px;
    }
    .bar-chart-legend {
      position: absolute;
      display: grid;
      float: right;
      top: 43px;
      right: 35px;
      font-size: 1.2rem;
      span {
        padding: 0.4rem 0;
      }
    }
    .graph-legend-container {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      margin-top: 20px;
      padding: 10px;
      .graph-legend {
        display: inline-block;
        width: 50%;
        float: left;
        text-align: left;
        padding: 3px 5px;
        &-rect {
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align: middle;
        }
        &-value {
          display: inline-block;
          vertical-align: middle;
          font-size: 1.1rem;
        }
        .graph-legend-rect-pie0 {
          background: #aca095;
        }
        .graph-legend-rect-pie1 {
          background: #4084f3;
        }
        .graph-legend-rect-pie2 {
          background: #4ed8c6;
        }
        .graph-legend-rect-pie3 {
          background: #efe9e5;
        }
        .graph-legend-rect-pie4 {
          background: #73b8e9;
        }
        .graph-legend-rect-pie5 {
          background: #e91e63;
        }
        .graph-legend-rect-doughnut0 {
          background: #4ed8c6;
        }
        .graph-legend-rect-doughnut1 {
          background: #2783f6;
        }
      }
    }

    ngx-charts-pie-chart .ngx-charts {
      width: 100%;
      padding-left: 20px;
    }
    .doughnut-chart .ngx-charts {
      float: none;
    }
    .doughnut-chart {
      .chart-legend div {
        width: 165px;
      }
      .legend-labels li {
        display: inline-block;
      }
    }
    .ngx-charts-outer.ng-trigger {
      width: 100% !important;
    }
    .chart-legend > div {
      width: 100% !important;
    }
    .chart-legend .legend-labels {
      width: auto !important;
    }
    .chart-legend .legend-label {
      margin: 5px;
    }
    .chart-legend .legend-label-color {
      height: 12px;
      width: 12px;
    }
    .chart-legend .legend-label-text {
      font-size: 10px;
    }
    .graph-width-half .ngx-charts {
      width: 100%;
    }
  }

  // 2칸 사이즈 그래프 타입
  .col-lg-2 .slice-container.add-type-graph {
    padding: 15px 2px;
  }
  .col-lg-3 .slice-container.add-type-graph {
    padding: 13px 5px 8px 5px;
  }
  // 4칸 사이즈 그래프 타입
  .col-lg-4 .slice-container.add-type-graph {
    padding: 2px 2px;
    .graph-title {
      font-size: 15px;
    }
    .graph-label-right {
      font-size: 10px;
      color: #6a6a6a;
      float: right;
    }
    .graph-label-left {
      font-size: 10px;
      color: #6a6a6a;
      float: left;
    }
  }
  // 6칸 사이즈 그래프
  .col-lg-6 .slice-container.add-type-graph {
    padding: 2px;
    .graph-container {
      width: 100%;
      //min-height: 220px;
    }
  }
  .col-md-6 .slice-container.add-type-graph,
  .col-lg-10 .slice-container.add-type-graph,
  .col-lg-11 .slice-container.add-type-graph,
  .col-lg-12 .slice-container.add-type-graph {
    padding: 2px 2px;
  }

  /*labeling 된 graph 타입*/
  .add-type-labeling-graph {
    padding: 70px 2px;
    .title-with-label {
      font-size: 24px;
      margin-bottom: 28px;
      .label-default {
        font-family: "Open Sans", sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 0.3rem 1.5rem;
        background: #09b894;
        border-radius: 1rem;
      }
    }
  }
  .add-type-labeling-graph:hover {
    padding: 68px 0;
  }

  // 한 컨테이너에서 4등분 된 labeling graph 타입
  .add-type-one-over-four [class*="col-"]:first-child {
    padding-left: 0 !important;
  }
  .add-type-one-over-four [class*="col-"]:last-child {
    padding-right: 0 !important;
  }
  .add-type-one-over-four .add-type-labeling-graph {
    padding: 30px 2px;
    .title-with-label {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
  }
  .add-type-one-over-four .add-type-labeling-graph:hover {
    padding: 28px 0;
  }

  // slice container 4등분
  .add-type-one-over-four {
    //float: left;
    .row {
      margin-bottom: 22px;
    }
    .row:last-child {
      margin-bottom: 0;
    }
  }

  .add-type-card-view {
    padding: 2px;
  }
  .add-type-card-view:hover {
    padding: 0;
  }

  .slice-container:hover,
  .col-lg-2 .slice-container.add-type-graph:hover,
  .col-lg-3 .slice-container.add-type-graph:hover,
  .col-lg-4 .slice-container.add-type-graph:hover,
  .col-lg-6 .slice-container.add-type-graph:hover,
  .col-lg-10 .slice-container.add-type-graph:hover,
  .col-lg-11 .slice-container.add-type-graph:hover,
  .col-lg-12 .slice-container.add-type-graph:hover,
  .col-md-6 .slice-container.add-type-graph:hover,
  .col-md-12 .slice-container.add-type-graph:hover {
    border: 2px solid #09b894;
  }
  .add-type-basic:hover,
  .col-lg-6 .slice-container.add-type-graph-with-title:hover {
    padding: 48px 58px 38px;
  }

  .col-lg-2 .slice-container.add-type-graph:hover {
    padding: 13px 0;
  }
  .col-lg-3 .slice-container.add-type-graph:hover {
    padding: 11px 3px 6px 3px;
  }
  .col-lg-4 .slice-container.add-type-graph:hover,
  .col-lg-6 .slice-container.add-type-graph:hover,
  .col-lg-10 .slice-container.add-type-graph:hover,
  .col-lg-11 .slice-container.add-type-graph:hover,
  .col-lg-12 .slice-container.add-type-graph:hover {
    padding: 0;
  }
}
.read-more-button {
  font-size: 1.2rem;
  margin: 0;
  background: #f1f1f1;
  text-align: center;
  padding: 6px 0;
  color: #9d9d9d;
  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
    padding-left: 3px;
  }
}

.content-border {
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  box-shadow: rgba(0, 0, 0, 0.5) 10px 10px 10px 0px;
  padding-top: 30px !important;
}
.content-border-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.content-none-text-align {
  text-align: unset !important;
}
.content-margin-left-right-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.half-content-padding-left-right {
  margin: 0 !important;
  padding: 0 20px !important;
}
.content-tabs {
  padding: 0 !important;
}

// **************** after filtering content common style ****************

// ******************** primeNG data table common style ********************
p-dataTable,
p-table {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  display: block;

  .ui-datatable .ui-datatable-thead > tr > th,
  .ui-table .ui-table-thead > tr > th {
    background: #f5f5f5;
    border: 1px solid #c0bebe;
    color: #6a6a6a;
    padding: 10px 10px;
  }
  .ui-datatable .ui-datatable-data > tr > td,
  .ui-table .ui-table-tbody > tr > td {
    padding: 0.4em 0.5em;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    border: 1px solid #c0bebe;
    color: #6a6a6a;
    padding: 10px 10px;
    background: white;
    font-size: 11px;
    word-break: break-all;
  }

  .ui-datatable .ui-column-filter {
    margin-top: 0.5em;
    padding: 7px 0;
    text-align: center;
    border: 1px solid #616161;
  }

  // datatable selectionMode selected cell
  .ui-state-highlight td {
    background: #09b894 !important;
    color: white !important;
  }

  // check box in Data table
  .ui-selection-column {
    width: 40px;
    padding: 10px 0px !important;
  }
  .ui-datatable .ui-selection-column .ui-chkbox-box {
    margin: 0 auto;
    border: 1px solid #979797;
    border-radius: 0;
  }

  // radio button in Data table
  .ui-datatable .ui-selection-column .ui-radiobutton-box {
    margin: 0 auto;
  }
  .ui-radiobutton-box.ui-state-active {
    border: 1px solid #09b894;
    background: #09b894;
    color: white;
  }

  .ui-sortable-column.ui-state-active {
    color: #555555;
  }

  // paginator in Data table
  .ui-paginator-bottom {
    background: #ffffff00;
    border: 0;
    margin-top: 10px;
    .ui-paginator-first,
    .ui-paginator-last {
      padding: 5px 13px;
    }
    .ui-paginator-page,
    .ui-paginator-prev,
    .ui-paginator-next {
      padding: 5px 10px;
    }
    .ui-paginator-element {
      border: none;
    }
  }

  // col-button in Data table
  .col-button .ui-button .ui-button-text {
    display: none;
  }
  .col-button .ui-button {
    padding: 0 5px;
    transition: unset;
  }
}
#fileAccessRequest
  .ui-datatable
  tbody
  > tr.ui-widget-content.ui-state-highlight
  > td {
  background: #485465;
  color: white;
}
#fileAccessRequest
  .ui-chkbox-box.ui-widget.ui-corner-all.ui-state-default.ui-state-active {
  background: #485465;
  border: 3px solid #fff;
}
#fileAccessRequest .ui-chkbox .ui-chkbox-icon {
  display: none;
}

.fa-forward:before {
  content: "\f105" !important;
  font-size: 24px;
}
.fa-backward:before {
  content: "\f104" !important;
  font-size: 24px;
}
.fa-step-forward:before {
  content: "\f101" !important;
  font-size: 24px;
}
.fa-step-backward:before {
  content: "\f100" !important;
  font-size: 24px;
}
a.ui-paginator-next:focus,
a.ui-paginator-prev:focus {
  outline: none;
}
// ******************** primeNG data table common style ********************

// ******************** primeNG dialog common style ********************
.ui-corner-all {
  border-radius: 0 !important;
}
.ui-dialog {
  font-family: "Open Sans", sans-serif !important;
  padding: 30px 30px 20px 40px !important;
}
.ui-dialog-content.ui-widget-content {
  .p-dialog-header {
    text-align: center;
    p {
      display: inline-block;
      border-bottom: 1px solid #ddd;
      padding: 0 13px 5px;
      font-size: 15px;
    }
  }
  div {
    margin-bottom: 15px;
    input,
    .ui-dropdown-label {
      padding: 5px 10px;
    }
    .ui-dropdown-trigger {
      text-align: center;
      span {
        vertical-align: middle;
      }
    }
  }
  .p-dialog-input-group {
    margin-bottom: 0;
    input {
      border-bottom: 0;
    }
  }
  div > span:nth-child(1) {
    display: inline-block;
    width: 26%;
    font-size: 12px;
    color: #646464;
  }
  div > span:nth-child(2) {
    font-size: 11pt;
    display: inline-block;
    width: 72%;
  }
  div:last-child {
    margin-bottom: 0px;
  }

  // select box style
  .ui-dropdown {
    width: 70% !important;
  }
  .ui-state-highlight {
    background: #09b894;
  }
}

div > textarea {
  margin-top: 8px;
  width: 100%;
  height: 140px;
}

button.dialog-btn.sendreply-btn {
  border: 0 !important;
  background: rgb(127, 127, 127) !important;
}
button.dialog-btn.cancel-btn {
  font-size: 12px;
  color: #878686 !important;
  border: 1px solid darkgray !important;
  background: white !important;
}
button.dialog-btn.refuse-btn {
  border: 0 !important;
  background: rgb(231, 76, 60) !important;
}
button.dialog-btn.accept-btn {
  border: 0 !important;
  background: #09b894 !important;
  font-size: 12px;
}

.ui-dialog-footer.ui-widget-content {
  border: 0;
}

p-footer > div {
  text-align: left;
  margin-bottom: 7px;
}

p-footer .ui-button {
  text-align: center;
  width: 157px;
  height: 30px;
}

.ui-inputtext {
  width: 70%;
}
// ******************** primeNG dialog common style ********************

// ******************** angular tree node common style ********************
.angular-tree-component {
  cursor: default;
}

tree-node-children .node-content-wrapper {
  padding: 0.5rem 0 0.5rem 4.5rem;
}

.node-content-wrapper {
  padding: 1rem 0 1rem 2rem;
  font-size: 1rem;
}

div.tree-children {
  position: relative;
  margin-left: 0;
  padding-left: 0;
}

tree-node > div .toggle-children-wrapper {
  position: absolute;
  display: inline-block;
  right: 2rem;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border: 1px solid #979797;
  margin-top: 1.1rem;
  margin-left: 0;
  z-index: 1;
  cursor: pointer;
}

tree-node > div .toggle-children-wrapper::before {
  content: "";
  display: inline-block;
  width: 7px;
  border-top: 1px solid #979797;
  position: absolute;
  top: 5px;
  left: 2px;
}

tree-node
  > div
  .toggle-children-wrapper.toggle-children-wrapper-collapsed::after {
  content: "";
  display: inline-block;
  height: 7px;
  border-left: 1px solid #979797;
  position: absolute;
  top: 2px;
  left: 5px;
}

tree-node > div .toggle-children-wrapper .toggle-children,
.node-drop-slot,
.toggle-children-placeholder {
  display: none;
}

tree-node-expander {
  width: 1px;
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  box-shadow: none;
}

.node-content-wrapper:hover,
.node-content-wrapper-focused {
  background: #fff;
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #ececec;
}

.tree-node.ng-star-inserted {
  border-bottom: 1px dashed #bababa;
}

tree-node-children .tree-node.ng-star-inserted {
  border-bottom: none;
}
// ******************** angular tree node common style ********************

// p-chart inside text style
.relative {
  position: relative;
}

.absolute-center {
  font-size: 1rem;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-center {
  text-align: center;
}
// p-chart inside text style

// amChart common style
.amcharts-chart-div a {
  display: none !important;
}
// amChart common style

// material common style
.mat-form-field {
  width: 100%;
}
.mat-form-field-flex {
  width: 100%;
}
.mat-form-field-infix {
  font-size: 1rem;
}
.mat-option {
  line-height: 28px;
  height: 28px;
  font-size: 1rem;
}
md-input-container.md-input-focused label {
  color: #09b894;
}
// material common style

#levelOneCancerView {
  display: none;
}
#levelTwoCancerView {
  display: none;
}
#levelZeroGeneView {
  display: none;
}
#levelOneGeneView {
  display: none;
}
#levelZeroVariantView {
  display: none;
}
#levelOneVariantView {
  display: none;
}

// level0 view chart graph common style -------------------------------------------------------
.ngx-charts .grid-panel.odd rect {
  fill: none !important;
}
ngx-charts-bar-horizontal-2d,
ngx-charts-bar-horizontal-stacked {
  z-index: 0;
}
.ngx-charts-guide-line-title {
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 0 0 0;
}
.ngx-charts-guide-line-title.left {
  float: left;
}
.ngx-charts-guide-line-title.right {
  float: right;
  margin-right: 15px;
}
.ngx-charts-guide-line-title.right.word-break {
  margin-right: 0;
}
.graph-with-guide-line {
  position: relative;
  width: 100%;
  min-height: 100px;
  margin-top: -20px;
  .ngx-charts-guide-line-label {
    position: absolute;
    width: 60px;
    label {
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
    }
  }
  ngx-charts-bar-horizontal-2d {
    width: 40%;
    margin-left: 40px;
  }
  .ngx-charts-guide-line {
    width: 90%;
    height: 150px;
    position: absolute;
    right: 20px;
    top: 10px;
    border-bottom: 1px solid #bababa;
    border-left: 1px solid #bababa;
    z-index: 0;
    &-empty-div {
      width: 45%;
      display: inline-block;
      min-height: 10px;
    }
    &-label-div {
      display: inline-block;
      width: 35%;
      height: 148px;
      border-left: 1px dashed #bababa;
      border-right: 1px dashed #bababa;
      text-align: left;
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 3px;
      p {
        font-size: 1rem;
        line-height: 13px;
        margin: 4.5px 0;
      }
    }
    &-count-div {
      display: inline-block;
      width: 5%;
      span {
        font-size: 1rem;
        position: absolute;
        top: 45%;
        right: 5px;
      }
    }
  }
}

#geneChartDiv1 {
  .ngx-charts-guide-line-label {
    label {
      line-height: 280px;
    }
  }
  .ngx-charts-guide-line {
    height: 270.5px;
  }
  .ngx-charts-guide-line-label-div {
    height: 268px;
    p {
      font-size: 1rem;
      line-height: 15px;
      margin-bottom: 5px;
    }
  }
}
#geneChartDiv2 {
  .ngx-charts-guide-line-label {
    label {
      line-height: 160px;
    }
  }
}
#geneChartDiv3 {
  .ngx-charts-guide-line-label {
    label {
      line-height: 160px;
    }
  }
}
#geneChartDiv4 {
  .ngx-charts-guide-line-label {
    label {
      line-height: 180px;
    }
  }
  .ngx-charts-guide-line {
    height: 170px;
  }
  .ngx-charts-guide-line-label-div {
    height: 168px;
  }
}
#geneChartDiv5 {
  .ngx-charts-guide-line-label {
    label {
      line-height: 145px;
    }
  }
  .ngx-charts-guide-line {
    height: 135px;
  }
  .ngx-charts-guide-line-label-div {
    height: 133px;
  }
}
.graph-container.graph-with-guide-line.variantChartDiv {
  ngx-charts-bar-horizontal-stacked {
    width: 70%;
  }
  ngx-charts-bar-horizontal-stacked.active {
    margin-left: 151px !important;
  }
  ngx-charts-bar-horizontal-stacked.none {
    margin-left: 122px !important;
  }
  margin: 45px 0 20px 0;
  .ngx-stacked-charts-guide-line-label {
    position: absolute;
    width: 141px;
    height: 200px;
    label {
      font-size: 1rem;
      font-weight: 400;
      width: 126px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  .ngx-stacked-charts-guide-line {
    position: absolute;
    top: -25px;
    right: 2px;
    width: 85%;
    height: 285px;
    border-left: 1px solid #bababa;
    z-index: 0;
    text-align: left;
    .ngx-stacked-charts-guide-line-container:nth-child(1)
      .ngx-stacked-charts-guide-line-label-div {
      top: 20px;
    }
    .ngx-stacked-charts-guide-line-container:nth-child(2)
      .ngx-stacked-charts-guide-line-label-div {
      top: 70px;
    }
    .ngx-stacked-charts-guide-line-container:nth-child(3)
      .ngx-stacked-charts-guide-line-label-div {
      top: 120px;
    }
    .ngx-stacked-charts-guide-line-container:nth-child(4)
      .ngx-stacked-charts-guide-line-label-div {
      top: 170px;
    }
    .ngx-stacked-charts-guide-line-container:nth-child(5)
      .ngx-stacked-charts-guide-line-label-div {
      top: 220px;
    }
    .ngx-stacked-charts-guide-line-container {
      display: inline-block;
      width: 85%;
      height: 50px;
      .ngx-stacked-charts-guide-line-label-div {
        position: absolute;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        label {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
    &-count-div {
      position: absolute;
      top: 0;
      right: 0;
      width: 15%;
      height: 285px;
      border-left: 1px solid #bababa;
      text-align: right;
      padding-right: 50px;
      label {
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        padding: 16px 0;
      }
      label.word-break {
        padding: 8.5px 0;
      }
    }
  }
}
.graph-container.graph-with-guide-line.variantChartDiv.snv {
  .ngx-stacked-charts-guide-line-label {
    label {
      height: 28px;
      text-align: left;
      padding-left: 20px;
    }
  }
}
.graph-container.graph-with-guide-line.variantChartDiv.cnv {
  .ngx-stacked-charts-guide-line-label label {
    text-align: left;
    padding-left: 30px;
  }
}
.graph-container.graph-with-guide-line.variantChartDiv.sv {
  .ngx-stacked-charts-guide-line-label label {
    text-align: left;
    padding-left: 10px;
  }
}
.graph-container.graph-with-guide-line.variantChartDiv.cnv,
.graph-container.graph-with-guide-line.variantChartDiv.sv {
  .ngx-stacked-charts-guide-line-label {
    label {
      line-height: 26px;
    }
  }
}
.ngx-charts-guide-line-title.gene.left {
  padding-left: 55px;
}
.ngx-charts-guide-line-title.gene.right {
  padding-right: 5px;
}
.ngx-charts-guide-line-title.variant.left {
  padding-left: 150px;
}
//.ngx-charts-guide-line-title.variant.right { padding-right: 15px; }
.ngx-stacked-charts-percent-label {
  display: block;
  margin-bottom: 0;
  color: #000;
}

.pie-chart-none > div {
  width: 20%;
  margin: 50px auto;
  background: #e9e9e9;
  label {
    padding: 20px 70px;
    font-weight: 400;
    margin: 0;
  }
}

.stacked-bar-chart-none {
  height: 250px;
}
.stacked-bar-chart-none > div {
  width: 20%;
  margin: 80px auto;
  background: #e9e9e9;
  label {
    padding: 20px 0;
    font-weight: 400;
    margin: 0;
  }
}

.quick-search > p {
  font-size: 1.2rem;
  text-align: center;
  margin: 0;
}
.quick-search {
  ul {
    list-style: none;
    padding: 10px 30px;
    margin: 0;
    text-align: center;
    li {
      font-size: 1.2rem;
      text-align: left;
      margin: 5px 0;
      padding: 5px 20px;
      background: #e9e9e9;
      border-radius: 7px;
      p {
        word-break: break-all;
      }
    }
    li:hover,
    li.active {
      background: #09b894;
      color: #fff;
      cursor: pointer;
    }
  }
}

label.linked {
  z-index: 1;
  cursor: pointer;
  text-decoration: underline;
  color: #008cba;
}
a.linked {
  cursor: pointer;
  text-decoration: underline;
}

.view-indicator {
  width: 6.5%;
  position: fixed;
  right: 8px;
  bottom: 10px;
  z-index: 1;
  text-align: center;
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      width: 100%;
      padding: 5px 14px;
      margin: 10px 0;
      box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.34);
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      font-size: 1rem;
    }
    li:hover {
      color: white;
      background: #09b894;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}

.red-text {
  color: red;
}
.blue-text {
  color: blue;
}
.detail-view-link-select-box {
  margin-right: 10px;
}
.common-all-btn {
  float: left;
  font-size: 1.2rem;
  padding: 0px 6px;
  border: 1px solid #bababa;
  color: #000;
  margin-left: 10px;
}
// Gene, Variant 축의 작은 cancer by gene graph의 all 버튼
.only-gene-all.only-gene-all {
  float: right;
  margin-top: -2px;
}
.common-all-btn:hover,
.only-gene-all:hover {
  background: #09b894;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

p-table.common-variant-all-data-table .ui-table .ui-table-thead > tr > th,
p-table.detail-view-data-table .ui-table .ui-table-thead > tr > th {
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
}
p-table.common-variant-all-data-table .ui-table .ui-table-tbody > tr > td,
p-table.detail-view-data-table .ui-table .ui-table-tbody > tr > td {
  overflow: unset;
  white-space: normal;
  text-overflow: unset;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
}
p-table.common-variant-all-data-table
  .ui-table
  .ui-table-tbody
  > tr
  > td.counting-td,
p-table.detail-view-data-table .ui-table .ui-table-tbody > tr > td.counting-td,
p-table.last-data-view-table .ui-table .ui-table-tbody > tr > td.counting-td {
  text-align: right;
}
p-table.last-data-view-table > .ui-table .ui-table-thead > tr > th {
  text-align: center;
  word-break: break-all;
}
p-table.common-variant-all-data-table .active {
  //animation: colorchange 5s;
  background: #09b894 !important;
  color: white !important;
}

p-dialog.quick-search-all .ui-dialog {
  padding: 0 !important;
  ul {
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      font-size: 1.2rem;
      text-align: left;
      padding: 5px 15px;
      background: #e9e9e9;
      border-radius: 7px;
      margin: 5px;
      min-width: 100px;
      word-break: break-all;
    }
    li:hover {
      background: #09b894;
      color: #fff;
      cursor: pointer;
    }
    li.quick-variant {
      min-width: 400px;
    }
  }
}
p-dialog.quick-search-all .ui-dialog .ui-dialog-content.ui-widget-content {
  overflow-x: hidden;
}

.btn.common-btn-small-default.ui-button {
  float: left;
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  background: none !important;
  box-shadow: none !important;
  border: 1px solid #a6a6a6 !important;
  color: #000 !important;
  padding: 1px 6px;
  margin-left: 10px;
  span {
    padding: 0;
  }
}
.btn.common-btn-small-default.ui-button:hover {
  border: 1px solid #09b894 !important;
  background: #09b894 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.text-color-green {
  color: #09b894;
}
/*level0 view chart graph common style*/

.mat-option {
  overflow: unset !important;
}

#flexLayout {
  flex-direction: column !important;
}
.noneGraph {
  display: none !important;
}

.all-list-dialog {
  .ui-dialog {
    padding: 0 !important;
    left: 30px !important;
    right: 30px !important;
  }
  .ui-dialog-content.ui-widget-content .ui-dropdown {
    width: 115px !important;
  }
}

/*data-explorer 페이지의 그리드 레이아웃 css*/
.grid-layout-chart-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 15% 72% 13%;
  grid-template-columns: 15% 72% 13%;
  grid-template-areas: "menu main right";
  margin-bottom: 10px;
}
.grid-layout-header {
  margin-top: 10px;
  label {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
  }
  .grid-header-left {
    float: left;
    padding-left: calc(15% + 10px);
    margin: 0;
  }
  .grid-header-right {
    float: right;
    width: 13%;
    margin: 0;
    text-align: left;
    padding-left: 10px;
  }
}
.grid-layout-header::after {
  content: "";
  display: table;
  clear: both;
}
.grid-layout-label {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: menu;
  display: table;
  height: 100%;
  border-right: 1px solid #bababa;
  margin-top: 5px;
  width: 100%;
  label {
    width: 100%;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    word-break: break-all;
  }
}
.grid-layout-chart {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: main;
  margin-left: -20px;
  margin-top: 10px;
  .ngx-stacked-charts-guide-line-container {
    margin-left: 20px;
    div {
      position: absolute;
    }
    label {
      font-size: 1rem;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    label.ngx-stacked-charts-percent-label {
      margin-top: -6px;
    }
  }
}
.grid-layout-counting {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  display: table;
  height: 100%;
  grid-area: right;
  border-left: 1px solid #bababa;
  margin-top: 5px;
  label {
    display: table-cell;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
}
/*data-explorer 페이지의 그리드 레이아웃 css*/

.completer-limit /deep/ .completer-dropdown {
  overflow-y: auto;
  max-height: 20rem;
}

/*
grid-row*/

.grid-row-1 {
  grid-row: 1;
  -ms-grid-row: 1;
}

.grid-row-2 {
  grid-row: 2;
  -ms-grid-row: 2;
}

.grid-row-3 {
  grid-row: 3;
  -ms-grid-row: 3;
}

.grid-row-4 {
  grid-row: 4;
  -ms-grid-row: 4;
}

.grid-row-5 {
  grid-row: 5;
  -ms-grid-row: 5;
}

.top-nav {
  padding: 0;
  margin: 0px;
  list-style: none;
  display: flex;
  li {
    padding: 0;
    a {
      width: 100%;
      text-decoration: none;
    }
  }
}
