.row {
  margin: 0; }

[class*="col-"] {
  padding: 0; }

.signin {
  text-align: center;
  width: 527px;
  margin: 0 auto;
  padding-bottom: 100px; }
  .signin-btn {
    margin-top: 31px; }
    .signin-btn .common-btn-default {
      padding: 15px 48px 10px 48px; }
    .signin-btn .common-btn-green {
      padding: 15px 80px 10px 80px;
      margin-left: 5px; }

.loginRow {
  display: flex; }

.logintitle {
  margin-bottom: 24px;
  text-align: left; }
  .logintitle-bold {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 35px;
    margin: 0;
    color: #1a1a1a; }
  .logintitle-small {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 15px;
    margin: 0;
    color: #434343; }

.btnsize {
  width: 170px;
  height: 30px;
  margin-right: 8px !important; }
  .btnsize-last {
    width: 170px;
    height: 30px; }

.input {
  width: 373px;
  height: 23px;
  border: solid 1px #979797;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 0px;
  display: block;
  line-height: normal;
  border-radius: 0px; }
  .input-top {
    width: 373px;
    height: 23px;
    border: solid 1px #979797;
    padding: 10px 16px;
    font-size: 15px;
    line-height: 1.3333333;
    border-radius: 0px;
    display: block;
    line-height: normal;
    margin-bottom: 4px; }

.deco {
  text-decoration: none !important; }

::-webkit-input-placeholder {
  color: #c6c6c6; }

.logintext {
  width: 151px;
  height: 94px;
  background-color: #26a1a4;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
  margin-left: 3px;
  border: 0px; }

.addbtn {
  margin-top: 16px;
  text-align: center;
  padding-top: 11px;
  border-top: solid 1px #979797; }

.checkbox {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: 15px;
  padding: 16px 0px 0px 0px !important;
  color: #434343;
  background: none;
  border: none; }

.backcolor-sign {
  background-color: #ffffff;
  border-top: 1px solid #c6c6c6; }
  .backcolor-sign .display-table {
    display: table;
    background-color: #ffffff;
    width: 100%;
    min-height: 90vh; }
  .backcolor-sign .display-table-cell {
    display: table-cell;
    vertical-align: middle; }

.activate {
  text-align: center;
  font-size: 1.6rem; }

.checkbox-color {
  color: #26a1a4; }
