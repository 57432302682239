.my-page {
  width: 455px;
  margin: 0 auto 60px;
  .my-page-user-info {
    table {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      th,
      td {
        vertical-align: middle;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
      }
      th {
        font-weight: 400;
        padding: 8px 20px;
        background: #f9f9f9;
        color: #6e6c6c;
        border-right: 1px solid #ddd;
      }
      td {
        text-align: center;
        width: 335px;
        color: #a8a8a8;
      }
      input {
        width: 164px;
        border-radius: 0;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  .my-page-btn {
    .common-btn-default {
      padding: 15px 75px 10px 75px;
      margin-right: 10px;
    }
    .common-btn-green {
      padding: 15px 70px 10px 70px;
    }
  }
}
.header{
    display: table;
    background-color: #f6f6f6;
    width: 100%;
    // border-top: 1px solid #c6c6c6;
    min-height: calc(100vh - 184px);
    .main{
      margin:10px;
    }
    .account-table{
      // margin-top: 50px;
      // min-height: 750px;
      // border: solid 1px #c6c6c6;
      // background-color: #f6f6f6;
      .table-white{
        // margin: 15px;
        min-height: 750px;
        border: solid 1px #dbdbdb;
        background-color: #ffffff;
      }
      .inform{
        margin: 20px;
        padding: 25px 60px;
        width: 610px;
        height: 300px;
        background-color: #ececec;
        .account-token-title{
          word-break: break-all;
          text-align: left;
          font-size: 16px;
          color: #b15151;
        }
        .account-token{
          word-break: break-all;
          text-align: left;
          font-size: 14px;
          color: #5151b1;
        }
        .account-title{
          text-align: center;
          font-size: 14px;
          color: #515151;
        }
        .account-data{
          text-align: left;
          font-size: 14px;
          color: #a0a0a0;
          padding: 5px 0px;
        }
        .account-change{
          text-align: right;
          font-size: 14px;
          color: #a0a0a0;
          padding: 5px 0px;
        }
        .account-main{
          display: flex;
          margin-top:50px;
        }
        .account-icon{
          font-size: 100px;
          color:#ffffff;
        }
        .account-inform{
          padding: 0px 0px 0px 50px;
        }
      }
    }

    .account-change{
      padding: 0px 0px 0px 150px;

    }
}
.account-confirm-button{
  float: right;
  width: 135px;
}
.account-change-input{
  margin-left: 5px;
  width: 55%;
}
