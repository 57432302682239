@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
.row {
  margin: 0; }

.no-margin {
  margin: 0; }

.margin-top-25 {
  margin-top: 25px !important; }

.margin-top-0 {
  margin-top: 0px !important; }

.margin-bottom-0 {
  margin-bottom: 0px !important; }

.margin-right-0 {
  margin-right: 0 !important; }

.started {
  display: flex;
  padding-left: 6px; }
  .started-case {
    display: flex; }

.background {
  display: table;
  width: 0; }

.image {
  font-size: 18px !important;
  color: white;
  margin-right: 5px; }
  .image-close {
    float: right;
    padding-top: 0px; }

.close {
  color: white !important;
  float: right;
  padding-top: 0px; }

.banner {
  width: 100%;
  height: 60px;
  color: #ffffff;
  background-color: #383838;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px; }
  .banner-kmaster {
    font-size: 18px; }
  .banner-else {
    font-size: 15px; }

.get-started {
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  background-image: url(/static/media/background.3cc2ba9d.jpg);
  background-repeat: no-repeat;
  background-size: cover; }
  .get-started-chart-position {
    display: inline-block;
    padding: 10px 12vw 20px 14.5vw;
    margin: auto; }
  .get-started-chart-table {
    display: inline-block;
    padding: 10px 12vw 20px 12vw;
    margin: auto; }
  .get-started-chart-center {
    width: 1010px; }
  .get-started-chart-centers {
    width: 1060px; }
  .get-started-release {
    display: inline-flex; }
  .get-started-title {
    display: table-cell;
    vertical-align: middle; }
  .get-started-case {
    display: flex;
    padding-top: 30px;
    padding-left: 50px;
    font-size: 15px;
    color: #ffffff; }
  .get-started-image {
    display: flex;
    padding-top: 30px;
    font-size: 15px;
    color: #ffffff; }
  .get-started-title-regular {
    font-weight: 300;
    letter-spacing: 1px; }
  .get-started-title-bold {
    margin-top: 0px;
    font-size: 15px;
    color: #ffffff;
    display: inline-block; }
  .get-started-title-regular {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #e0e0e0; }
  .get-started-btn .common-btn-default {
    padding: 15px 68px 10px 68px;
    border: 1px solid white;
    color: white; }
  .get-started-btn .common-btn-green {
    margin-left: 10px;
    padding: 15px 100px 10px 100px;
    border: 1px solid #09b894 !important;
    background: #09b894 !important;
    color: #fff !important; }

.home-summary-title {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 70px; }
  .home-summary-title-bold {
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #2c2c2c;
    margin-bottom: 5px; }
  .home-summary-title-small {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #151515; }

.home-bottom {
  height: 58px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  line-height: 4; }
  .home-bottom-sponsor {
    width: 50%;
    background-color: #33312c; }
  .home-bottom-app {
    width: 50%;
    background-color: rgba(78, 78, 78, 0.7); }
  .home-bottom-arrow {
    position: absolute;
    width: 100%;
    font-size: 20px; }

.home-summary-content {
  text-align: center;
  margin-bottom: 70px; }
  .home-summary-content .home-summary-card {
    display: inline-block;
    min-height: 218px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin-right: 40px; }
    .home-summary-content .home-summary-card-title {
      background: #09b894;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 100;
      color: white;
      border-radius: 16px;
      padding: 6px 0 2px 0;
      margin-bottom: 20px; }
    .home-summary-content .home-summary-card-content {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      color: #676767;
      margin-top: 15px; }

.icon-bottom {
  display: flex; }
  .icon-bottom-sponser {
    width: 50%;
    text-align: center; }
  .icon-bottom-family-site {
    padding: 20px; }
  .icon-bottom-app {
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto; }
  .icon-bottom-appitem {
    font-size: 12px;
    padding: 7px 10px 0px 10px;
    height: 50px; }

.test {
  height: 240px; }

.MainTitltle {
  font-size: 33px;
  padding-top: 80px; }

.subTitltle {
  font-size: 18px;
  padding-top: 15px; }
  .subTitltle-btn {
    padding-top: 3px; }

.row {
  margin: 0; }

[class*="col-"] {
  padding: 0; }

.registration {
  text-align: center;
  width: 650px;
  margin: 0 auto;
  padding-bottom: 40px; }
  .registration-certified {
    text-align: center;
    width: 600px;
    margin: 0 auto;
    padding-top: 66px; }
  .registration .input-required {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 15px;
    text-align: left;
    margin-bottom: 20px; }
  .registration .input-lg {
    margin-bottom: 15px; }
  .registration #registrationRepwd {
    margin-bottom: 10px; }
  .registration-btn {
    margin-top: 20px; }
    .registration-btn .common-btn-default {
      padding: 15px 72px 10px 72px;
      margin-right: 13px; }
    .registration-btn .common-btn-green {
      padding: 15px 69px 10px 69px; }
  .registration .signin-link {
    text-align: left;
    margin-top: 15px; }
    .registration .signin-link a {
      font-family: "Roboto", "Arial", "Helvetica", sans-serif;
      font-size: 15px;
      color: #333;
      text-decoration: none; }
    .registration .signin-link svg {
      color: #09b894;
      margin-right: 3px; }

.backButton {
  width: 220px;
  height: 45px;
  border: solid 1px #979797 !important;
  border-radius: 0px !important;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  color: #979797 !important;
  font-size: 14px !important; }

.backcolor {
  background-color: #c7c7c7;
  border-top: 1px solid #c6c6c6; }

.nextButton {
  width: 220px;
  height: 45px;
  margin-left: 10px !important;
  background-color: #26a1a4 !important;
  border-radius: 0px !important;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  color: #ffffff !important;
  font-size: 14px !important; }
  .nextButton-login {
    width: 451px;
    height: 45px;
    background-color: #26a1a4 !important;
    margin-top: 9px;
    border-radius: 0px !important;
    font-family: AppleSDGothicNeo !important;
    color: #ffffff !important;
    font-size: 14px !important; }

.disableNextButton {
  width: 220px;
  height: 45px;
  margin-left: 10px !important;
  background-color: #c6c6c6 !important;
  border-radius: 0px !important;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  color: #ffffff !important;
  font-size: 14px !important; }
  .disableNextButton-login {
    width: 451px;
    height: 45px;
    background-color: #26a1a4 !important;
    margin-top: 9px;
    border-radius: 0px !important;
    font-family: AppleSDGothicNeo !important;
    color: #ffffff !important;
    font-size: 14px !important; }

.warming {
  font-size: 15px;
  color: #ff4343;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  margin: 5px 0px 0px 100px;
  float: left;
  display: flex; }

.ChevronRight {
  float: right; }

.link {
  text-decoration: none !important; }

.backgroundimage {
  width: 100%;
  height: 338px;
  border: solid 1px #979797;
  background-color: rgba(64, 64, 64, 0.5);
  background-image: url(/static/media/joincertified.747b13da.jpg);
  margin-bottom: 10px; }

.stepper {
  padding: 60px 0px 0px 0px !important; }
  .stepper-label {
    font-size: 35px !important;
    color: #26a1a4 !important; }
  .stepper-nonlabel {
    font-size: 35px !important; }
  .stepper-line span {
    font-size: 15px !important; }

.email {
  padding-left: 5px;
  padding-top: 3px; }

.row {
  margin: 0; }

[class*="col-"] {
  padding: 0; }

.signin {
  text-align: center;
  width: 527px;
  margin: 0 auto;
  padding-bottom: 100px; }
  .signin-btn {
    margin-top: 31px; }
    .signin-btn .common-btn-default {
      padding: 15px 48px 10px 48px; }
    .signin-btn .common-btn-green {
      padding: 15px 80px 10px 80px;
      margin-left: 5px; }

.loginRow {
  display: flex; }

.logintitle {
  margin-bottom: 24px;
  text-align: left; }
  .logintitle-bold {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 35px;
    margin: 0;
    color: #1a1a1a; }
  .logintitle-small {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 15px;
    margin: 0;
    color: #434343; }

.btnsize {
  width: 170px;
  height: 30px;
  margin-right: 8px !important; }
  .btnsize-last {
    width: 170px;
    height: 30px; }

.input {
  width: 373px;
  height: 23px;
  border: solid 1px #979797;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.3333333;
  border-radius: 0px;
  display: block;
  line-height: normal;
  border-radius: 0px; }
  .input-top {
    width: 373px;
    height: 23px;
    border: solid 1px #979797;
    padding: 10px 16px;
    font-size: 15px;
    line-height: 1.3333333;
    border-radius: 0px;
    display: block;
    line-height: normal;
    margin-bottom: 4px; }

.deco {
  text-decoration: none !important; }

::-webkit-input-placeholder {
  color: #c6c6c6; }

.logintext {
  width: 151px;
  height: 94px;
  background-color: #26a1a4;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
  margin-left: 3px;
  border: 0px; }

.addbtn {
  margin-top: 16px;
  text-align: center;
  padding-top: 11px;
  border-top: solid 1px #979797; }

.checkbox {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: 15px;
  padding: 16px 0px 0px 0px !important;
  color: #434343;
  background: none;
  border: none; }

.backcolor-sign {
  background-color: #ffffff;
  border-top: 1px solid #c6c6c6; }
  .backcolor-sign .display-table {
    display: table;
    background-color: #ffffff;
    width: 100%;
    min-height: 90vh; }
  .backcolor-sign .display-table-cell {
    display: table-cell;
    vertical-align: middle; }

.activate {
  text-align: center;
  font-size: 1.6rem; }

.checkbox-color {
  color: #26a1a4; }

.forgot-password-content {
  width: 457px;
  margin: 130px 20px; }
  .forgot-password-content .forgot-password-btn {
    margin-top: 20px; }
    .forgot-password-content .forgot-password-btn .common-btn-default {
      padding: 15px 74px 10px 74px;
      margin-right: 13px; }
    .forgot-password-content .forgot-password-btn .common-btn-green {
      padding: 15px 88px 10px 88px; }

.form-control {
  height: 30px;
  width: 100%;
  font-size: 14px; }

.my-page {
  width: 455px;
  margin: 0 auto 60px; }
  .my-page .my-page-user-info table {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
    .my-page .my-page-user-info table th,
    .my-page .my-page-user-info table td {
      vertical-align: middle;
      font-family: "Open Sans", sans-serif;
      font-size: 14px; }
    .my-page .my-page-user-info table th {
      font-weight: 400;
      padding: 8px 20px;
      background: #f9f9f9;
      color: #6e6c6c;
      border-right: 1px solid #ddd; }
    .my-page .my-page-user-info table td {
      text-align: center;
      width: 335px;
      color: #a8a8a8; }
    .my-page .my-page-user-info table input {
      width: 164px;
      border-radius: 0;
      margin: 0 auto;
      text-align: center; }
  .my-page .my-page-btn .common-btn-default {
    padding: 15px 75px 10px 75px;
    margin-right: 10px; }
  .my-page .my-page-btn .common-btn-green {
    padding: 15px 70px 10px 70px; }

.header {
  display: table;
  background-color: #f6f6f6;
  width: 100%;
  min-height: calc(100vh - 184px); }
  .header .main {
    margin: 10px; }
  .header .account-table .table-white {
    min-height: 750px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff; }
  .header .account-table .inform {
    margin: 20px;
    padding: 25px 60px;
    width: 610px;
    height: 300px;
    background-color: #ececec; }
    .header .account-table .inform .account-token-title {
      word-break: break-all;
      text-align: left;
      font-size: 16px;
      color: #b15151; }
    .header .account-table .inform .account-token {
      word-break: break-all;
      text-align: left;
      font-size: 14px;
      color: #5151b1; }
    .header .account-table .inform .account-title {
      text-align: center;
      font-size: 14px;
      color: #515151; }
    .header .account-table .inform .account-data {
      text-align: left;
      font-size: 14px;
      color: #a0a0a0;
      padding: 5px 0px; }
    .header .account-table .inform .account-change {
      text-align: right;
      font-size: 14px;
      color: #a0a0a0;
      padding: 5px 0px; }
    .header .account-table .inform .account-main {
      display: flex;
      margin-top: 50px; }
    .header .account-table .inform .account-icon {
      font-size: 100px;
      color: #ffffff; }
    .header .account-table .inform .account-inform {
      padding: 0px 0px 0px 50px; }
  .header .account-change {
    padding: 0px 0px 0px 150px; }

.account-confirm-button {
  float: right;
  width: 135px; }

.account-change-input {
  margin-left: 5px;
  width: 55%; }

@charset "UTF-8";
.header-logo {
  display: inline-block;
  width: 120px;
  height: 100%;
  vertical-align: super;
  cursor: pointer; }

.datafooter {
  background: #f6f6f6;
  color: #d1d1d1;
  padding: 0px 30px;
  border-top: 15px;
  margin-top: 15px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .datafooter .footer-select {
    width: 230px;
    height: 20px;
    background: #ffffff;
    color: #000000;
    font-size: 8px;
    border-radius: 0px;
    border: solid 1px #dddddd; }
    @media (max-width: 1500px) {
      .datafooter .footer-select {
        width: 160px; } }
  .datafooter .footer-map {
    width: 78%;
    height: 55px;
    text-align: center;
    padding-top: 5px; }
  .datafooter .footer-selectdiv {
    width: 15%;
    text-align: center; }
  .datafooter .footer-contact {
    width: 7%;
    font-size: 8px;
    color: #d1d1d1;
    cursor: pointer;
    text-decoration: underline; }
  .datafooter .footer-logo {
    width: 20%;
    text-align: center;
    padding-top: 15px;
    display: flex;
    float: left; }
  .datafooter .footer-addr {
    margin-top: 20px; }
  .datafooter .footer-accent {
    padding-top: 5px;
    text-align: center;
    font-size: 11px;
    line-height: 1.3; }
  .datafooter .footer-release {
    font-size: 8px;
    color: #8a8a8a; }
  .datafooter .footer-icon {
    font-size: 10px; }
  .datafooter .footer-name {
    padding: 0px 9px; }
  .datafooter .footer-line {
    width: 1px;
    height: 41px;
    border-left: solid 1px #d1d1d1; }
  .datafooter .footer-selectmenu {
    font-size: 10px;
    min-height: 20px;
    padding: 0px; }

.footer {
  background: #383838;
  color: #d1d1d1;
  padding: 2.5px 20px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .footer .footer-select {
    width: 250px;
    height: 20px;
    background: #ffffff;
    color: #000000;
    font-size: 8px;
    border-radius: 0px;
    border: solid 1px #dddddd; }
    @media (max-width: 1500px) {
      .footer .footer-select {
        width: 160px; } }
  .footer .footer-map {
    width: 60%;
    height: 55px;
    text-align: center; }
  .footer .footer-selectdiv {
    width: 15%;
    text-align: center; }
  .footer .footer-name {
    padding: 0px 9px; }
  .footer .footer-contact {
    width: 5%;
    font-size: 8px;
    color: #d1d1d1;
    cursor: pointer;
    text-decoration: underline; }
  .footer .footer-logo {
    width: 20%;
    text-align: center;
    padding-right: 20px;
    display: flex;
    float: left; }
  .footer .footer-addr {
    margin-top: 20px;
    display: flex; }
    @media (max-width: 1800px) {
      .footer .footer-addr {
        display: block; } }
  .footer .footer-accent {
    text-align: center;
    font-size: 13px; }
  .footer .footer-release {
    font-size: 8px;
    color: #8a8a8a; }
  .footer .footer-line {
    width: 1px;
    height: 41px;
    border-left: solid 1px #d1d1d1; }
  .footer .footer-icon {
    font-size: 11px; }
  .footer .footer-selectmenu {
    font-size: 10px !important;
    min-height: 0px !important;
    padding: 0px; }

@media (min-width: 1200px) {
  .col-lg-4,
  .col-lg-8 {
    height: calc((100vw / 3) - 6vw); }
  .half-col-md {
    height: calc((100vw / 8) + 0.8vw); }
  .half-col-md:nth-child(2) {
    margin-bottom: 10px; }
  .chart-legend {
    position: absolute;
    right: 30px; }
  .half-container-ngx-graph {
    width: 100%;
    height: 180px; } }

.graph-container {
  margin-top: 10px; }

.ngx-charts-guide-line {
  width: 84.5% !important; }

@media (min-width: 992px) and (max-width: 1199px) {
  .ngx-charts-guide-line {
    width: 89.5% !important; } }

html,
body {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif !important; }

body .row {
  margin: 0; }

[class*="col-"] {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.no-outline {
  outline: none !important; }

.no-border {
  border: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-right-margin {
  margin-right: 0 !important; }

.margin-left-15 {
  margin-left: 15px; }

.margin-top-50 {
  margin-top: 40px; }

.margin-top-minus-50 {
  margin-top: -50px; }

.margin-bottom-30 {
  margin-bottom: 30px !important; }

.common-border-radius-0 {
  border-radius: 0 !important; }

.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

svg.ng-star-inserted {
  vertical-align: middle !important; }

.mat-expansion-indicator::after {
  margin-bottom: 8px;
  color: #575252; }

p.before-filtering-navigation-title:focus {
  outline: none; }

mat-drawer.side-nav {
  font-family: "Open Sans", sans-serif;
  background: white;
  border-top: 1px solid #717171;
  border-right: 2px solid #ddd;
  padding: 0;
  margin: -1px 0;
  /**
  before filtering view(sidenav)
   */ }
  mat-drawer.side-nav .before-filtering {
    font-family: "Open Sans", sans-serif;
    padding-left: 0;
    padding-right: 0; }
    mat-drawer.side-nav .before-filtering .overview-navigator {
      width: 100%;
      padding: 14px 0;
      text-align: center;
      background: #bbb;
      color: #fff;
      border-bottom: 1px solid #fff;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.05rem; }
    mat-drawer.side-nav .before-filtering ul.nav-tabs {
      border-bottom: none; }
    mat-drawer.side-nav .before-filtering .nav-tabs {
      border-bottom: 1px solid #ececec; }
      mat-drawer.side-nav .before-filtering .nav-tabs li {
        margin-bottom: 0;
        display: inline-block;
        border-right: 1px solid #ddd;
        width: 33.3%;
        padding: 0.55rem 0; }
      mat-drawer.side-nav .before-filtering .nav-tabs li:last-child {
        border-right: none; }
      mat-drawer.side-nav .before-filtering .nav-tabs-full {
        width: 100% !important; }
        mat-drawer.side-nav .before-filtering .nav-tabs-full a {
          text-align: center;
          padding: 10px 50px; }
    mat-drawer.side-nav .before-filtering .nav-tabs > li > a {
      text-align: center;
      color: #bfbfbf;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.04rem;
      border: 0;
      border-radius: 0;
      padding: 0.8rem 0;
      margin-right: 0; }
    mat-drawer.side-nav .before-filtering .nav-tabs > li.active,
    mat-drawer.side-nav .before-filtering .nav-tabs > li.active:hover,
    mat-drawer.side-nav .before-filtering .nav-tabs > li.active a:hover,
    mat-drawer.side-nav .before-filtering .nav-tabs > li.active a {
      background: #bbbbbb;
      color: white; }
    mat-drawer.side-nav .before-filtering .nav > li > a:hover {
      background: white; }
    mat-drawer.side-nav .before-filtering .sidenav-toggle-btn-left {
      position: absolute;
      top: 68px;
      right: 23px;
      padding: 4px 10px;
      background-color: #ffffff00;
      border-color: gray; }
    mat-drawer.side-nav .before-filtering .tab-pane form {
      padding: 1rem 2rem 1.7rem; }
    mat-drawer.side-nav .before-filtering .tab-pane form.side-nav-input {
      padding: 0 0 2rem; }
    mat-drawer.side-nav .before-filtering .tab-pane form.side-nav-input-with-checkbox {
      padding: 0.5rem 0 0 0; }
    mat-drawer.side-nav .before-filtering .form-group,
    mat-drawer.side-nav .before-filtering .input-group {
      width: 100%; }
    mat-drawer.side-nav .before-filtering .form-control {
      height: 35px;
      font-size: 1.4rem; }
    mat-drawer.side-nav .before-filtering .form-control::-webkit-input-placeholder {
      color: #d5d5d5;
      font-size: 1.3rem; }
    mat-drawer.side-nav .before-filtering .form-control::-moz-placeholder {
      color: #d5d5d5;
      font-size: 1.3rem; }
    mat-drawer.side-nav .before-filtering .form-control:-ms-input-placeholder {
      color: #d5d5d5;
      font-size: 1.3rem; }
    mat-drawer.side-nav .before-filtering .form-control::-ms-input-placeholder {
      color: #d5d5d5;
      font-size: 1.3rem; }
    mat-drawer.side-nav .before-filtering .form-control::placeholder {
      color: #d5d5d5;
      font-size: 1.3rem; }
    mat-drawer.side-nav .before-filtering .before-filtering-title {
      margin-top: 35px;
      font-size: 20px; }
    mat-drawer.side-nav .before-filtering .before-filtering-figure {
      font-size: 16px;
      margin-right: 5px; }
    mat-drawer.side-nav .before-filtering .checkbox label {
      font-size: 16px;
      font-weight: 400;
      margin-left: 5px; }
    mat-drawer.side-nav .before-filtering .checkbox-title {
      margin-left: 0;
      padding-left: 0; }
    mat-drawer.side-nav .before-filtering .checkbox span.label-default {
      float: right;
      font-family: "Open Sans", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-top: 0.3rem;
      padding: 0.3rem 1.2rem;
      background: #6a706f;
      border-radius: 10px; }
    mat-drawer.side-nav .before-filtering .radio-inline.radio-first {
      margin-left: 5px; }
    mat-drawer.side-nav .before-filtering .radio-inline {
      font-size: 16px; }
    mat-drawer.side-nav .before-filtering .from-to-go-search {
      margin-top: 10px; }
      mat-drawer.side-nav .before-filtering .from-to-go-search .input-group-addon,
      mat-drawer.side-nav .before-filtering .from-to-go-search .form-control {
        border-right: none; }
      mat-drawer.side-nav .before-filtering .from-to-go-search .input-group-addon:last-child {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        cursor: pointer;
        backgroun-color: #30383b; }
      mat-drawer.side-nav .before-filtering .from-to-go-search .input-group-addon {
        background-color: #404040;
        color: white; }
    mat-drawer.side-nav .before-filtering #genes {
      padding-bottom: 20px; }
    mat-drawer.side-nav .before-filtering #mutations {
      padding-bottom: 20px; }
  mat-drawer.side-nav .admin-sidenav-menu {
    padding: 15.5px 40px;
    margin: 0;
    font-weight: 600;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.86) !important;
    background: white !important; }
  mat-drawer.side-nav .admin-sidenav-menu-active, mat-drawer.side-nav .admin-sidenav-menu.active, mat-drawer.side-nav .admin-sidenav-menu-active.active {
    color: white !important;
    background: #485465 !important; }
  mat-drawer.side-nav .admin-sidenav-menu-active .mat-expansion-panel-header-title {
    color: white; }
  mat-drawer.side-nav .admin-sidenav-menu-active:focus {
    outline: none; }
  mat-drawer.side-nav .admin-sidenav p.before-filtering-navigation-title.active {
    background: #989898;
    color: white;
    outline: none !important; }
  mat-drawer.side-nav .admin-sidenav p.before-filtering-navigation-title.active:focus {
    outline: none !important; }
  mat-drawer.side-nav .admin-sidenav .mat-expansion-panel-header {
    font-family: "Open Sans", sans-serif;
    font-weight: 600; }
  mat-drawer.side-nav .admin-sidenav .mat-expansion-panel-body {
    font-family: "Open Sans", sans-serif;
    padding: 0;
    background: #f7f7f7; }
    mat-drawer.side-nav .admin-sidenav .mat-expansion-panel-body p {
      margin: 0;
      padding: 12px 40px;
      font-size: 13px;
      color: #5e5c5c; }

mat-drawer-content.mat-drawer-content .after-filtering {
  padding-left: 0;
  padding-right: 0; }

.after-filtering .common-button-with-tab-nav {
  display: inline-block;
  width: 100%;
  height: 42px;
  background: white;
  border-bottom: 2px solid #dddddd; }
  .after-filtering .common-button-with-tab-nav .sidenav-toggle-btn-right {
    position: absolute;
    top: 68px;
    padding: 4px 10px; }
  .after-filtering .common-button-with-tab-nav .common-side-nav-toggle-left {
    display: inline-block;
    width: 33px;
    height: 42px;
    background: #09b894;
    font-size: 1rem;
    color: white;
    border-radius: 0;
    border: none;
    vertical-align: top; }
  .after-filtering .common-button-with-tab-nav .common-side-nav-toggle-left:active,
  .after-filtering .common-button-with-tab-nav .common-side-nav-toggle-left:focus {
    background: #09b894 !important;
    color: white !important;
    border-color: unset !important;
    box-shadow: none !important; }
  .after-filtering .common-button-with-tab-nav .nav-tabs {
    display: inline-block;
    height: 100%;
    border-bottom: none;
    vertical-align: middle;
    padding-left: 62px; }
  .after-filtering .common-button-with-tab-nav .nav-tabs li a {
    border-radius: 0;
    border: none;
    padding: 20px 35px;
    margin: 0; }
  .after-filtering .common-button-with-tab-nav .nav-tabs li.active a,
  .after-filtering .common-button-with-tab-nav .nav-tabs li.active a:hover,
  .after-filtering .common-button-with-tab-nav .nav-tabs li.active a:focus {
    border: none;
    border-bottom: 6px solid #09b894 !important;
    color: #5f5f5f; }
  .after-filtering .common-button-with-tab-nav .nav-tabs li,
  .after-filtering .common-button-with-tab-nav .nav-tabs li a {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #bfbfbf;
    height: 100%; }
  .after-filtering .common-button-with-tab-nav .save-query {
    display: inline-block;
    float: right;
    padding: 12px 0; }
  .after-filtering .common-button-with-tab-nav .save-query button {
    font-family: "Open Sans", sans-serif;
    font-size: 12px; }
  .after-filtering .common-button-with-tab-nav span {
    display: inline-block;
    text-align: center;
    vertical-align: middle; }
    .after-filtering .common-button-with-tab-nav span .common-radius-btn {
      padding: 5px 22px; }
  .after-filtering .common-button-with-tab-nav .search-conditions span {
    text-align: left; }

.after-filtering .common-data-info-nav {
  display: inline-block;
  width: 100%;
  height: 230px;
  background: white;
  border-bottom: 2px solid #dddddd; }

.after-filtering .side-nav-data-filter {
  background: #fff;
  display: inline-block; }
  .after-filtering .side-nav-data-filter-container {
    display: inline-block;
    padding: 6px; }
  .after-filtering .side-nav-data-filter-circle {
    display: inline-block;
    background: #e2e2e2;
    border-radius: 2rem;
    padding-right: 15px; }
    .after-filtering .side-nav-data-filter-circle .side-nav-data-filter-white-arrow {
      display: inline-block;
      background: #fff;
      margin: 4px 5px 4px 4px;
      padding: 0 0.5rem;
      border-radius: 1rem; }
      .after-filtering .side-nav-data-filter-circle .side-nav-data-filter-white-arrow i {
        color: #e2e2e2;
        font-size: 1rem; }
    .after-filtering .side-nav-data-filter-circle .side-nav-data-filter-category {
      color: #636363;
      font-size: 1rem; }
    .after-filtering .side-nav-data-filter-circle .side-nav-data-filter-between-arrow {
      color: #ababab;
      margin: 0 0.4rem;
      font-size: 1rem; }
    .after-filtering .side-nav-data-filter-circle .side-nav-data-filter-value {
      display: inline-block;
      color: #636363;
      font-size: 1rem; }

.select-service {
  display: inline-block;
  float: right;
  margin-top: -40px;
  margin-right: 30px; }
  .select-service .ui-dropdown {
    width: 163px !important;
    border-radius: 0; }
  .select-service .ui-dropdown .ui-dropdown-trigger {
    width: 30px; }
  .select-service .ui-dropdown .ui-dropdown-clear-icon {
    right: 3.5em; }
  .select-service .ui-dropdown-label.ui-placeholder {
    font-size: 10px;
    padding: 6.5px 40px 6.5px 10px; }
  .select-service span {
    text-align: center;
    vertical-align: middle; }

.common-before-filtering-tab .nav-tabs {
  margin-left: 110px;
  margin-top: -43px; }

.common-before-filtering-tab .nav-tabs > li.active > a,
.common-before-filtering-tab .nav-tabs > li.active > a:hover {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 5px solid #09b894 !important;
  padding: 10px 15px !important;
  color: #5f5f5f !important; }

.common-before-filtering-tab .nav-tabs > li > a {
  border: none;
  font-size: 13px;
  color: #bfbfbf; }

.common-before-filtering-tab .nav-tabs > li > a:hover {
  background: none; }

mat-drawer-container.mat-drawer-container {
  overflow: unset; }

mat-drawer-content.mat-drawer-content {
  min-height: 178vh;
  background: #f6f6f7;
  padding-bottom: 1rem;
  overflow: hidden; }

mat-expansion-panel.mat-expansion-panel {
  box-shadow: none !important;
  border-bottom: 2px solid #ddd; }
  mat-expansion-panel.mat-expansion-panel mat-expansion-panel-header span {
    font-family: "Open Sans", sans-serif !important;
    font-size: 1.4rem;
    font-weight: 600; }
  mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .checkbox label {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-body {
    padding: 0 20px; }

mat-expansion-panel.mat-expansion-panel:last-child {
  border-bottom: none; }

.mat-expanded.mat-expansion-panel-spacing {
  margin: 0; }

.radio-item {
  display: inline-block;
  position: relative; }

.radio-item label {
  color: #666;
  font-weight: normal; }

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #595959;
  background-color: transparent; }

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: #5f5f5f; }

input[type="radio"] + label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 13px;
  height: 13px;
  padding: 3px;
  margin-right: 5px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: unset;
  border-radius: 50%;
  vertical-align: bottom; }

input[type="radio"]:checked + label:before {
  background-color: #09b894; }

.radioButton {
  padding: 0.3rem 2rem;
  border-bottom: 1px solid #f4f4f4; }

.radioButton label {
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer; }

.radioButton .label-default {
  float: right;
  margin-top: 4px;
  border-radius: 10px;
  padding: 2px 10px; }

.reported {
  display: inline-block;
  padding: 0;
  margin-right: 10px; }

.reported:last-child {
  margin: 0; }

.reported:first-child {
  padding: 0; }

.checkbox:first-child {
  padding-top: 1rem; }

.checkbox {
  text-align: left;
  margin: 0 !important;
  padding: 0.3rem 1.5rem; }

.checkbox label:after {
  content: "";
  display: table;
  clear: both; }

.checkbox label {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif !important;
  font-size: 15px !important;
  color: #000000;
  padding: 0 !important;
  min-height: unset !important;
  display: inline-block; }
  .checkbox label span:nth-child(3) {
    font-size: 1rem !important;
    margin-left: -0.2rem !important; }

.checkbox label:nth-child(2) {
  font-size: 1rem !important;
  margin-left: -0.2rem !important; }

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #000000;
  border-radius: 0;
  width: 1.3em;
  height: 1.3em;
  float: left !important;
  margin-right: 0.5em;
  padding: 0 !important; }

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 10px !important;
  line-height: 0;
  top: 50%;
  left: 15%; }

.checkbox label input[type="checkbox"] {
  display: none; }

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0; }

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  opacity: 1; }

.checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5; }

.input-group input {
  font-family: "Open Sans", sans-serif;
  border-top: 1px solid #616161;
  border-left: 1px solid #616161;
  border-bottom: 1px solid #616161; }

.input-group input.input-group-input-border-right {
  border-right: 1px solid #616161; }

.input-group-addon {
  font-family: "Open Sans", sans-serif;
  font-weight: 300 !important;
  background: #a1a1a1 !important;
  color: white !important;
  border: 1px solid #a1a1a1 !important; }

.common-input-group-addon-white {
  background: white !important;
  border: none !important;
  color: #595959 !important;
  padding: 6px 5px !important; }

.input-required {
  color: #ff4343; }

.input-lg {
  border-radius: 0 !important;
  margin-bottom: 9px; }

.common-input-group {
  margin-bottom: 0 !important;
  border-bottom: none !important; }

.form-control::-webkit-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px; }

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px; }

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px; }

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px; }

/* Internet Explorer 10-11 */
.form-control:-ms-input-placeholder {
  font-family: "Open Sans", sans-serif;
  font-size: 15px; }

/* Microsoft Edge */
.common-form-title {
  margin-bottom: 30px;
  text-align: center; }
  .common-form-title-bold {
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    margin: 0; }
  .common-form-title-small {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #888888; }

.common-radius-btn {
  font-family: "Open Sans", sans-serif;
  border-radius: 17px !important;
  border: 1px solid #a6a6a6 !important;
  color: #a6a6a6 !important;
  -webkit-transition-property: background;
  transition-property: background;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear; }

.common-radius-btn:hover {
  background-color: #09b894 !important;
  border: 1px solid #09b894 !important;
  color: white !important; }

.btn.common-btn-default,
.btn.common-btn-green,
.btn.common-btn-red {
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px; }

.btn.common-btn-default,
.btn.common-btn-default:hover,
.btn.common-btn-default:active,
.btn.common-btn-default:focus,
.btn.common-btn-default.none {
  background: none !important;
  box-shadow: none !important;
  color: #000; }

.btn.common-btn-green,
.btn.common-btn-green:hover,
.btn.common-btn-green:active,
.btn.common-btn-green:focus,
.btn.common-btn-default.active {
  border: 1px solid #09b894 !important;
  background: #09b894 !important;
  color: #ffffff !important;
  box-shadow: none !important; }

.btn.common-btn-red,
.btn.common-btn-red:hover,
.btn.common-btn-red:active,
.btn.common-btn-red:focus {
  border: 1px solid #e94d4d !important;
  background: #e94d4d !important;
  color: #ffffff !important;
  box-shadow: none !important; }

.display-table {
  display: table;
  background-color: #ffffff;
  width: 100%;
  min-height: 90vh; }

.display-table-cell {
  display: table-cell;
  vertical-align: middle; }

.ui-paginator .ui-paginator-page.ui-state-active {
  color: #6a6a6a !important;
  border: 1px solid #cfcfcf !important;
  background: white !important;
  border-radius: 0; }

.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
  background: white !important; }

.ui-paginator .ui-dropdown {
  border-radius: 0;
  padding: 2px 5px; }

.ui-dropdown:not(.ui-state-disabled):hover {
  background: none !important; }

.ui-chkbox .ui-chkbox-box {
  height: 1.14em !important; }

.ui-widget.ui-button,
.ui-button {
  border: 1px solid #343a40 !important;
  color: white !important;
  background: #343a40 !important; }

.ui-datatable
table
button.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-text-empty.ng-star-inserted {
  background: none !important;
  color: #979797 !important;
  border: 1px solid #979797 !important;
  font-family: "Open Sans", sans-serif !important;
  padding: 3px 5px; }

.ui-datatable
table
button.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-text-empty.ng-star-inserted:hover {
  background: #09b894 !important;
  color: white !important;
  border: 1px solid #09b894 !important; }

.datatable-column-icon-material {
  vertical-align: bottom;
  font-size: 20px; }

.card-view {
  /*system service management style card view*/
  /*system service management style card view*/ }
  .card-view-title {
    border-bottom: 1px solid #dddddd;
    padding: 13px 25px;
    margin: 0;
    color: #707070;
    font-size: 15px; }
    .card-view-title-right {
      float: right; }
  .card-view-content-text {
    padding: 43.7px 0 43.7px; }
    .card-view-content-text p {
      display: inline-block;
      margin: 0; }
    .card-view-content-text-usage {
      font-size: 12px;
      color: #a6a6a6;
      float: left;
      margin: 0; }
    .card-view-content-text-percent {
      font-size: 2.5rem;
      color: #09b894;
      margin: 0; }
    .card-view-content-text-percent-unit {
      font-size: 12px;
      color: #a6a6a6; }
  .card-view-content {
    padding: 44px 23px; }
    .card-view-content-btn {
      display: inline-block;
      width: 44%;
      vertical-align: middle;
      padding-right: 50px; }
      .card-view-content-btn .btn-success-green,
      .card-view-content-btn .btn-fail-default,
      .card-view-content-btn .btn-success-default,
      .card-view-content-btn .btn-fail-red {
        font-size: 14px;
        display: block;
        margin-bottom: 8px; }
      .card-view-content-btn .btn-success-green,
      .card-view-content-btn .btn-success-default {
        width: 100%;
        padding: 11px 0; }
      .card-view-content-btn .btn-fail-default,
      .card-view-content-btn .btn-fail-red {
        width: 100%;
        padding: 11px 0; }
      .card-view-content-btn .btn-success-green,
      .card-view-content-btn .btn-success-green:hover,
      .card-view-content-btn .btn-success-green:focus {
        background: #09b894 !important;
        color: white !important; }
      .card-view-content-btn .btn-fail-red,
      .card-view-content-btn .btn-fail-red:hover,
      .card-view-content-btn .btn-fail-red:focus {
        background: #e94d4d !important;
        color: white !important; }
    .card-view-content-summary {
      display: inline-block;
      vertical-align: middle; }
      .card-view-content-summary .margin-bottom-3 p {
        margin-bottom: 3px; }
      .card-view-content-summary-left-text, .card-view-content-summary-right-text {
        display: inline-block;
        text-align: left;
        font-size: 15px; }
      .card-view-content-summary-left-text {
        padding-right: 11px;
        color: #a6a6a6; }
      .card-view-content-summary-right-text {
        color: #474747; }
  .card-view-content-progress {
    width: 60%;
    margin: 20px auto 0px; }
    .card-view-content-progress-summary {
      padding: 5px 36px 20px; }
      .card-view-content-progress-summary p .card-view-content-left-text {
        font-size: 12px;
        color: #a6a6a6; }
      .card-view-content-progress-summary p .card-view-content-right-text {
        font-size: 12px;
        color: #474747; }
  .card-view-status p {
    font-size: 2.5rem;
    color: #09b894;
    margin-bottom: 0;
    padding: 35px 0; }
  .card-view-summary {
    text-align: left;
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid #ddd; }
    .card-view-summary-title {
      font-size: 12px;
      color: #a6a6a6; }
    .card-view-summary-description {
      float: right;
      font-size: 12px;
      color: #474747; }
  .card-view-control-btn .btn-success-green,
  .card-view-control-btn .btn-fail-default,
  .card-view-control-btn .btn-success-default,
  .card-view-control-btn .btn-fail-red {
    border-radius: 0; }
  .card-view-control-btn .btn-success-green,
  .card-view-control-btn .btn-success-green:hover,
  .card-view-control-btn .btn-success-green:active,
  .card-view-control-btn .btn-success-green:focus {
    width: 81%;
    padding: 9px 0;
    margin-top: 30px;
    border-color: #09b894 !important;
    background: #09b894 !important;
    color: white !important;
    box-shadow: none; }
  .card-view-control-btn .btn-fail-red,
  .card-view-control-btn .btn-fail-red:hover,
  .card-view-control-btn .btn-fail-red:active,
  .card-view-control-btn .btn-fail-red:focus {
    width: 81%;
    padding: 9px 0;
    margin: 10px 0 20px;
    border-color: #ff5757 !important;
    background: #ff5757 !important;
    color: white !important;
    box-shadow: none; }
  .card-view-control-btn .btn-fail-default,
  .card-view-control-btn .btn-fail-default:hover,
  .card-view-control-btn .btn-fail-default:active,
  .card-view-control-btn .btn-fail-default:focus {
    width: 81%;
    padding: 9px 0;
    margin: 10px 0 20px;
    background: white !important;
    box-shadow: none; }
  .card-view-control-btn .btn-success-default,
  .card-view-control-btn .btn-success-default:hover,
  .card-view-control-btn .btn-success-default:active,
  .card-view-control-btn .btn-success-default:focus {
    width: 81%;
    padding: 9px 0;
    margin-top: 30px;
    background: white !important;
    box-shadow: none; }

.form-control.service-select {
  float: right;
  width: 160px; }

.span-underline {
  text-decoration: underline; }

.dataTable-column-link {
  color: #2fa7d4;
  font-size: 13px;
  text-decoration: underline; }
  .dataTable-column-link i {
    padding-left: 5px; }

.dataTable-column-link.ng-star-inserted,
.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.dataTable-column-btn {
  padding: 6px 20px !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: normal !important;
  font-weight: initial !important;
  border-radius: 0 !important;
  border: 1px solid #a6a6a6 !important;
  color: #a6a6a6 !important; }

.dataTable-column-btn:hover {
  background: #09b894 !important;
  border: 1px solid #09b894 !important;
  color: white !important; }

.dataTable-column-btn[disabled]:hover {
  background: white !important;
  border: 1px solid #a6a6a6 !important;
  color: #a6a6a6 !important; }

.after-filtering-content-in-tab-searchForm {
  float: right;
  margin-top: 10px; }

.periodSearchForm {
  float: right;
  margin-bottom: 15px; }
  .periodSearchForm label {
    font-size: 16px;
    font-weight: 400;
    vertical-align: bottom;
    margin-right: 10px; }
  .periodSearchForm input.form-control {
    width: 270px; }

.onlySearchForm {
  margin-top: 0 !important;
  margin-bottom: 15px; }

.textWithSearchForm {
  margin-top: 22px !important; }

.after-filtering-content .ui-datatable-tablewrapper {
  margin-top: 15px; }

.after-filtering-content .label-default {
  background-color: #343a40;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px; }

.after-filtering-content-title {
  font-size: 22px;
  font-weight: 600; }

.after-filtering-content-title-description {
  font-size: 16px; }

.after-filtering-content-title-amount {
  float: left;
  padding-top: 10px;
  font-size: 18px; }

.after-filtering-content-button {
  float: right;
  margin-bottom: 10px; }
  .after-filtering-content-button #add-btn {
    font-size: 16px;
    padding: 3px 8px; }
  .after-filtering-content-button #modify-btn,
  .after-filtering-content-button #delete-btn {
    font-size: 16px;
    padding: 3px 5px; }

.inline-group-of-slice {
  padding: 1rem 0.5rem 0;
  text-align: center;
  /*labeling 된 graph 타입*/ }
  .inline-group-of-slice .slice-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14); }
  .inline-group-of-slice .add-type-basic {
    padding: 50px 60px 40px;
    text-align: left; }
    .inline-group-of-slice .add-type-basic span.title {
      font-size: 22px;
      font-weight: 600; }
    .inline-group-of-slice .add-type-basic p.title-description {
      font-size: 16px; }
    .inline-group-of-slice .add-type-basic .title-button {
      margin-top: 30px; }
      .inline-group-of-slice .add-type-basic .title-button .column-btn {
        background-color: #e0e0e0;
        color: #5e5e5e;
        padding: 10px 23px; }
    .inline-group-of-slice .add-type-basic form.table-title-search-form {
      float: right; }
    .inline-group-of-slice .add-type-basic .header {
      width: 100%;
      display: inline-block;
      color: #474747;
      text-align: left; }
      .inline-group-of-slice .add-type-basic .header .header-title {
        font-size: 23px;
        margin: 0;
        display: inline-block; }
      .inline-group-of-slice .add-type-basic .header .header-title.one-line-table-header-title {
        padding-bottom: 25px; }
      .inline-group-of-slice .add-type-basic .header .header-title-description {
        font-size: 17px;
        margin-bottom: 18px; }
      .inline-group-of-slice .add-type-basic .header .header-title-link {
        color: #29bfff;
        text-decoration: underline;
        margin-right: 4rem; }
      .inline-group-of-slice .add-type-basic .header .header-title-link:last-child {
        margin-right: 0; }
    .inline-group-of-slice .add-type-basic .header-btn {
      display: inline-block;
      float: right;
      padding-top: 20px; }
      .inline-group-of-slice .add-type-basic .header-btn .common-btn-default,
      .inline-group-of-slice .add-type-basic .header-btn .common-btn-green {
        font-size: 15px;
        padding: 8px 15px; }
    .inline-group-of-slice .add-type-basic .header-btn.one-line-header-btn {
      padding-top: 15px; }
    .inline-group-of-slice .add-type-basic .header-graph-btn {
      display: inline-block;
      float: right;
      margin-top: 10px; }
    .inline-group-of-slice .add-type-basic .header-column-btn {
      background-color: #e0e0e0;
      color: #5e5e5e;
      padding: 10px 23px; }
    .inline-group-of-slice .add-type-basic .header-selector {
      text-align: right;
      float: right;
      margin-top: 10px; }
      .inline-group-of-slice .add-type-basic .header-selector span {
        text-align: center; }
    .inline-group-of-slice .add-type-basic .excel-download::after {
      content: "";
      display: table;
      clear: both; }
    .inline-group-of-slice .add-type-basic .excel-download {
      float: right; }
      .inline-group-of-slice .add-type-basic .excel-download-btn {
        margin-bottom: 10px;
        font-size: 12px; }
        .inline-group-of-slice .add-type-basic .excel-download-btn i {
          font-size: 14px;
          vertical-align: sub; }
      .inline-group-of-slice .add-type-basic .excel-download-btn:hover, .inline-group-of-slice .add-type-basic .excel-download-btn:active {
        border: 1px solid #09b894;
        background: #09b894 !important;
        color: white;
        font-weight: 500;
        outline: none; }
    .inline-group-of-slice .add-type-basic .progress-with-graph {
      display: inline-block;
      width: 100%; }
      .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress {
        width: 15%;
        display: inline-block;
        vertical-align: middle !important; }
      .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description {
        width: 12%;
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px; }
        .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect {
          margin-bottom: 15px; }
          .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect-green, .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect-orange, .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect-red {
            display: inline-block;
            width: 12px;
            height: 12px; }
          .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect-green {
            background: #09b894; }
          .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect-orange {
            background: #ff9722; }
          .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect-red {
            background: #f24d4d; }
          .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect span {
            padding-left: 10px;
            font-size: 12px;
            color: #474747; }
        .inline-group-of-slice .add-type-basic .progress-with-graph .content-progress-description-rect:last-child {
          margin-bottom: 0; }
      .inline-group-of-slice .add-type-basic .progress-with-graph .content-graph {
        width: 71%;
        min-height: 200px;
        display: inline-block;
        vertical-align: middle; }
    .inline-group-of-slice .add-type-basic .header-tooltip {
      position: relative;
      display: inline-block; }
    .inline-group-of-slice .add-type-basic .header-tooltip .tooltip-div {
      visibility: hidden;
      width: 500px;
      font-size: 1.2rem;
      padding: 10px 20px 20px;
      position: absolute;
      top: 44px;
      z-index: 1;
      color: #555;
      background-color: #fff;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in; }
    .inline-group-of-slice .add-type-basic .header-tooltip .tooltip-div::after {
      content: "";
      position: absolute;
      left: 20px;
      top: -5px;
      box-sizing: border-box;
      border: 5px solid black;
      border-color: transparent transparent #fff #fff;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      box-shadow: -4px 3px 10px 0 rgba(0, 0, 0, 0.3); }
    .inline-group-of-slice .add-type-basic .header-tooltip:hover .tooltip-div {
      visibility: visible; }
  .inline-group-of-slice .add-type-graph .header {
    padding-bottom: 20px; }
  .inline-group-of-slice .add-type-graph .header-title {
    float: left;
    font-weight: 600;
    color: #636363;
    display: inline-block;
    margin: 0; }
  .inline-group-of-slice .add-type-graph .header-counting-label {
    font-weight: bold; }
  .inline-group-of-slice .add-type-graph .header-label-rect {
    display: inline-block;
    background: #f9f9f9;
    border: 1px solid #e4e4e4;
    padding: 2px 15px;
    margin-left: 2px;
    font-size: 1.2rem; }
    .inline-group-of-slice .add-type-graph .header-label-rect span {
      color: #636363; }
  .inline-group-of-slice .add-type-graph .card-type-header {
    text-align: right;
    font-size: 1.2rem;
    border-bottom: 1px solid #bababa;
    padding: 0.5rem 1.2rem; }
  .inline-group-of-slice .add-type-graph .graph-guide {
    font-size: 1.2rem;
    float: left;
    padding-top: 0.5rem;
    padding-left: 1.3rem; }
  .inline-group-of-slice .add-type-graph .graph-title {
    width: 90%;
    padding: 10px 0 5px 0;
    margin: 0 auto;
    text-align: left;
    font-size: 1rem; }
    .inline-group-of-slice .add-type-graph .graph-title .right-title {
      float: right; }
  .inline-group-of-slice .add-type-graph .graph-container {
    display: grid;
    width: 100%;
    height: 100%; }
  .inline-group-of-slice .add-type-graph .graph-container.one-over-four-big {
    display: grid;
    width: 100%;
    height: 100%;
    min-height: 530px; }
    .inline-group-of-slice .add-type-graph .graph-container.one-over-four-big .ngx-charts-outer {
      margin: 0 auto; }
  .inline-group-of-slice .add-type-graph .graph-container.one-over-four-big.small-pie-graph {
    min-height: 270px; }
  .inline-group-of-slice .add-type-graph .bar-chart-legend {
    position: absolute;
    display: grid;
    float: right;
    top: 43px;
    right: 35px;
    font-size: 1.2rem; }
    .inline-group-of-slice .add-type-graph .bar-chart-legend span {
      padding: 0.4rem 0; }
  .inline-group-of-slice .add-type-graph .graph-legend-container {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px; }
    .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend {
      display: inline-block;
      width: 50%;
      float: left;
      text-align: left;
      padding: 3px 5px; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend-rect {
        width: 15px;
        height: 15px;
        display: inline-block;
        vertical-align: middle; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend-value {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.1rem; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-pie0 {
        background: #aca095; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-pie1 {
        background: #4084f3; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-pie2 {
        background: #4ed8c6; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-pie3 {
        background: #efe9e5; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-pie4 {
        background: #73b8e9; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-pie5 {
        background: #e91e63; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-doughnut0 {
        background: #4ed8c6; }
      .inline-group-of-slice .add-type-graph .graph-legend-container .graph-legend .graph-legend-rect-doughnut1 {
        background: #2783f6; }
  .inline-group-of-slice .add-type-graph ngx-charts-pie-chart .ngx-charts {
    width: 100%;
    padding-left: 20px; }
  .inline-group-of-slice .add-type-graph .doughnut-chart .ngx-charts {
    float: none; }
  .inline-group-of-slice .add-type-graph .doughnut-chart .chart-legend div {
    width: 165px; }
  .inline-group-of-slice .add-type-graph .doughnut-chart .legend-labels li {
    display: inline-block; }
  .inline-group-of-slice .add-type-graph .ngx-charts-outer.ng-trigger {
    width: 100% !important; }
  .inline-group-of-slice .add-type-graph .chart-legend > div {
    width: 100% !important; }
  .inline-group-of-slice .add-type-graph .chart-legend .legend-labels {
    width: auto !important; }
  .inline-group-of-slice .add-type-graph .chart-legend .legend-label {
    margin: 5px; }
  .inline-group-of-slice .add-type-graph .chart-legend .legend-label-color {
    height: 12px;
    width: 12px; }
  .inline-group-of-slice .add-type-graph .chart-legend .legend-label-text {
    font-size: 10px; }
  .inline-group-of-slice .add-type-graph .graph-width-half .ngx-charts {
    width: 100%; }
  .inline-group-of-slice .col-lg-2 .slice-container.add-type-graph {
    padding: 15px 2px; }
  .inline-group-of-slice .col-lg-3 .slice-container.add-type-graph {
    padding: 13px 5px 8px 5px; }
  .inline-group-of-slice .col-lg-4 .slice-container.add-type-graph {
    padding: 2px 2px; }
    .inline-group-of-slice .col-lg-4 .slice-container.add-type-graph .graph-title {
      font-size: 15px; }
    .inline-group-of-slice .col-lg-4 .slice-container.add-type-graph .graph-label-right {
      font-size: 10px;
      color: #6a6a6a;
      float: right; }
    .inline-group-of-slice .col-lg-4 .slice-container.add-type-graph .graph-label-left {
      font-size: 10px;
      color: #6a6a6a;
      float: left; }
  .inline-group-of-slice .col-lg-6 .slice-container.add-type-graph {
    padding: 2px; }
    .inline-group-of-slice .col-lg-6 .slice-container.add-type-graph .graph-container {
      width: 100%; }
  .inline-group-of-slice .col-md-6 .slice-container.add-type-graph,
  .inline-group-of-slice .col-lg-10 .slice-container.add-type-graph,
  .inline-group-of-slice .col-lg-11 .slice-container.add-type-graph,
  .inline-group-of-slice .col-lg-12 .slice-container.add-type-graph {
    padding: 2px 2px; }
  .inline-group-of-slice .add-type-labeling-graph {
    padding: 70px 2px; }
    .inline-group-of-slice .add-type-labeling-graph .title-with-label {
      font-size: 24px;
      margin-bottom: 28px; }
      .inline-group-of-slice .add-type-labeling-graph .title-with-label .label-default {
        font-family: "Open Sans", sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 0.3rem 1.5rem;
        background: #09b894;
        border-radius: 1rem; }
  .inline-group-of-slice .add-type-labeling-graph:hover {
    padding: 68px 0; }
  .inline-group-of-slice .add-type-one-over-four [class*="col-"]:first-child {
    padding-left: 0 !important; }
  .inline-group-of-slice .add-type-one-over-four [class*="col-"]:last-child {
    padding-right: 0 !important; }
  .inline-group-of-slice .add-type-one-over-four .add-type-labeling-graph {
    padding: 30px 2px; }
    .inline-group-of-slice .add-type-one-over-four .add-type-labeling-graph .title-with-label {
      font-size: 1.4rem;
      margin-bottom: 20px; }
  .inline-group-of-slice .add-type-one-over-four .add-type-labeling-graph:hover {
    padding: 28px 0; }
  .inline-group-of-slice .add-type-one-over-four .row {
    margin-bottom: 22px; }
  .inline-group-of-slice .add-type-one-over-four .row:last-child {
    margin-bottom: 0; }
  .inline-group-of-slice .add-type-card-view {
    padding: 2px; }
  .inline-group-of-slice .add-type-card-view:hover {
    padding: 0; }
  .inline-group-of-slice .slice-container:hover,
  .inline-group-of-slice .col-lg-2 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-3 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-4 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-6 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-10 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-11 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-12 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-md-6 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-md-12 .slice-container.add-type-graph:hover {
    border: 2px solid #09b894; }
  .inline-group-of-slice .add-type-basic:hover,
  .inline-group-of-slice .col-lg-6 .slice-container.add-type-graph-with-title:hover {
    padding: 48px 58px 38px; }
  .inline-group-of-slice .col-lg-2 .slice-container.add-type-graph:hover {
    padding: 13px 0; }
  .inline-group-of-slice .col-lg-3 .slice-container.add-type-graph:hover {
    padding: 11px 3px 6px 3px; }
  .inline-group-of-slice .col-lg-4 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-6 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-10 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-11 .slice-container.add-type-graph:hover,
  .inline-group-of-slice .col-lg-12 .slice-container.add-type-graph:hover {
    padding: 0; }

.read-more-button {
  font-size: 1.2rem;
  margin: 0;
  background: #f1f1f1;
  text-align: center;
  padding: 6px 0;
  color: #9d9d9d; }
  .read-more-button i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.6rem;
    padding-left: 3px; }

.content-border {
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  box-shadow: rgba(0, 0, 0, 0.5) 10px 10px 10px 0px;
  padding-top: 30px !important; }

.content-border-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.content-none-text-align {
  text-align: unset !important; }

.content-margin-left-right-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.half-content-padding-left-right {
  margin: 0 !important;
  padding: 0 20px !important; }

.content-tabs {
  padding: 0 !important; }

p-dataTable,
p-table {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  display: block; }
  p-dataTable .ui-datatable .ui-datatable-thead > tr > th,
  p-dataTable .ui-table .ui-table-thead > tr > th,
  p-table .ui-datatable .ui-datatable-thead > tr > th,
  p-table .ui-table .ui-table-thead > tr > th {
    background: #f5f5f5;
    border: 1px solid #c0bebe;
    color: #6a6a6a;
    padding: 10px 10px; }
  p-dataTable .ui-datatable .ui-datatable-data > tr > td,
  p-dataTable .ui-table .ui-table-tbody > tr > td,
  p-table .ui-datatable .ui-datatable-data > tr > td,
  p-table .ui-table .ui-table-tbody > tr > td {
    padding: 0.4em 0.5em;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    border: 1px solid #c0bebe;
    color: #6a6a6a;
    padding: 10px 10px;
    background: white;
    font-size: 11px;
    word-break: break-all; }
  p-dataTable .ui-datatable .ui-column-filter,
  p-table .ui-datatable .ui-column-filter {
    margin-top: 0.5em;
    padding: 7px 0;
    text-align: center;
    border: 1px solid #616161; }
  p-dataTable .ui-state-highlight td,
  p-table .ui-state-highlight td {
    background: #09b894 !important;
    color: white !important; }
  p-dataTable .ui-selection-column,
  p-table .ui-selection-column {
    width: 40px;
    padding: 10px 0px !important; }
  p-dataTable .ui-datatable .ui-selection-column .ui-chkbox-box,
  p-table .ui-datatable .ui-selection-column .ui-chkbox-box {
    margin: 0 auto;
    border: 1px solid #979797;
    border-radius: 0; }
  p-dataTable .ui-datatable .ui-selection-column .ui-radiobutton-box,
  p-table .ui-datatable .ui-selection-column .ui-radiobutton-box {
    margin: 0 auto; }
  p-dataTable .ui-radiobutton-box.ui-state-active,
  p-table .ui-radiobutton-box.ui-state-active {
    border: 1px solid #09b894;
    background: #09b894;
    color: white; }
  p-dataTable .ui-sortable-column.ui-state-active,
  p-table .ui-sortable-column.ui-state-active {
    color: #555555; }
  p-dataTable .ui-paginator-bottom,
  p-table .ui-paginator-bottom {
    background: #ffffff00;
    border: 0;
    margin-top: 10px; }
    p-dataTable .ui-paginator-bottom .ui-paginator-first,
    p-dataTable .ui-paginator-bottom .ui-paginator-last,
    p-table .ui-paginator-bottom .ui-paginator-first,
    p-table .ui-paginator-bottom .ui-paginator-last {
      padding: 5px 13px; }
    p-dataTable .ui-paginator-bottom .ui-paginator-page,
    p-dataTable .ui-paginator-bottom .ui-paginator-prev,
    p-dataTable .ui-paginator-bottom .ui-paginator-next,
    p-table .ui-paginator-bottom .ui-paginator-page,
    p-table .ui-paginator-bottom .ui-paginator-prev,
    p-table .ui-paginator-bottom .ui-paginator-next {
      padding: 5px 10px; }
    p-dataTable .ui-paginator-bottom .ui-paginator-element,
    p-table .ui-paginator-bottom .ui-paginator-element {
      border: none; }
  p-dataTable .col-button .ui-button .ui-button-text,
  p-table .col-button .ui-button .ui-button-text {
    display: none; }
  p-dataTable .col-button .ui-button,
  p-table .col-button .ui-button {
    padding: 0 5px;
    -webkit-transition: unset;
    transition: unset; }

#fileAccessRequest
.ui-datatable
tbody
> tr.ui-widget-content.ui-state-highlight
> td {
  background: #485465;
  color: white; }

#fileAccessRequest
.ui-chkbox-box.ui-widget.ui-corner-all.ui-state-default.ui-state-active {
  background: #485465;
  border: 3px solid #fff; }

#fileAccessRequest .ui-chkbox .ui-chkbox-icon {
  display: none; }

.fa-forward:before {
  content: "\f105" !important;
  font-size: 24px; }

.fa-backward:before {
  content: "\f104" !important;
  font-size: 24px; }

.fa-step-forward:before {
  content: "\f101" !important;
  font-size: 24px; }

.fa-step-backward:before {
  content: "\f100" !important;
  font-size: 24px; }

a.ui-paginator-next:focus,
a.ui-paginator-prev:focus {
  outline: none; }

.ui-corner-all {
  border-radius: 0 !important; }

.ui-dialog {
  font-family: "Open Sans", sans-serif !important;
  padding: 30px 30px 20px 40px !important; }

.ui-dialog-content.ui-widget-content .p-dialog-header {
  text-align: center; }
  .ui-dialog-content.ui-widget-content .p-dialog-header p {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    padding: 0 13px 5px;
    font-size: 15px; }

.ui-dialog-content.ui-widget-content div {
  margin-bottom: 15px; }
  .ui-dialog-content.ui-widget-content div input,
  .ui-dialog-content.ui-widget-content div .ui-dropdown-label {
    padding: 5px 10px; }
  .ui-dialog-content.ui-widget-content div .ui-dropdown-trigger {
    text-align: center; }
    .ui-dialog-content.ui-widget-content div .ui-dropdown-trigger span {
      vertical-align: middle; }

.ui-dialog-content.ui-widget-content .p-dialog-input-group {
  margin-bottom: 0; }
  .ui-dialog-content.ui-widget-content .p-dialog-input-group input {
    border-bottom: 0; }

.ui-dialog-content.ui-widget-content div > span:nth-child(1) {
  display: inline-block;
  width: 26%;
  font-size: 12px;
  color: #646464; }

.ui-dialog-content.ui-widget-content div > span:nth-child(2) {
  font-size: 11pt;
  display: inline-block;
  width: 72%; }

.ui-dialog-content.ui-widget-content div:last-child {
  margin-bottom: 0px; }

.ui-dialog-content.ui-widget-content .ui-dropdown {
  width: 70% !important; }

.ui-dialog-content.ui-widget-content .ui-state-highlight {
  background: #09b894; }

div > textarea {
  margin-top: 8px;
  width: 100%;
  height: 140px; }

button.dialog-btn.sendreply-btn {
  border: 0 !important;
  background: #7f7f7f !important; }

button.dialog-btn.cancel-btn {
  font-size: 12px;
  color: #878686 !important;
  border: 1px solid darkgray !important;
  background: white !important; }

button.dialog-btn.refuse-btn {
  border: 0 !important;
  background: #e74c3c !important; }

button.dialog-btn.accept-btn {
  border: 0 !important;
  background: #09b894 !important;
  font-size: 12px; }

.ui-dialog-footer.ui-widget-content {
  border: 0; }

p-footer > div {
  text-align: left;
  margin-bottom: 7px; }

p-footer .ui-button {
  text-align: center;
  width: 157px;
  height: 30px; }

.ui-inputtext {
  width: 70%; }

.angular-tree-component {
  cursor: default; }

tree-node-children .node-content-wrapper {
  padding: 0.5rem 0 0.5rem 4.5rem; }

.node-content-wrapper {
  padding: 1rem 0 1rem 2rem;
  font-size: 1rem; }

div.tree-children {
  position: relative;
  margin-left: 0;
  padding-left: 0; }

tree-node > div .toggle-children-wrapper {
  position: absolute;
  display: inline-block;
  right: 2rem;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border: 1px solid #979797;
  margin-top: 1.1rem;
  margin-left: 0;
  z-index: 1;
  cursor: pointer; }

tree-node > div .toggle-children-wrapper::before {
  content: "";
  display: inline-block;
  width: 7px;
  border-top: 1px solid #979797;
  position: absolute;
  top: 5px;
  left: 2px; }

tree-node
> div
.toggle-children-wrapper.toggle-children-wrapper-collapsed::after {
  content: "";
  display: inline-block;
  height: 7px;
  border-left: 1px solid #979797;
  position: absolute;
  top: 2px;
  left: 5px; }

tree-node > div .toggle-children-wrapper .toggle-children,
.node-drop-slot,
.toggle-children-placeholder {
  display: none; }

tree-node-expander {
  width: 1px; }

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  box-shadow: none; }

.node-content-wrapper:hover,
.node-content-wrapper-focused {
  background: #fff; }

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #ececec; }

.tree-node.ng-star-inserted {
  border-bottom: 1px dashed #bababa; }

tree-node-children .tree-node.ng-star-inserted {
  border-bottom: none; }

.relative {
  position: relative; }

.absolute-center {
  font-size: 1rem;
  position: absolute;
  top: 53%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.text-center {
  text-align: center; }

.amcharts-chart-div a {
  display: none !important; }

.mat-form-field {
  width: 100%; }

.mat-form-field-flex {
  width: 100%; }

.mat-form-field-infix {
  font-size: 1rem; }

.mat-option {
  line-height: 28px;
  height: 28px;
  font-size: 1rem; }

md-input-container.md-input-focused label {
  color: #09b894; }

#levelOneCancerView {
  display: none; }

#levelTwoCancerView {
  display: none; }

#levelZeroGeneView {
  display: none; }

#levelOneGeneView {
  display: none; }

#levelZeroVariantView {
  display: none; }

#levelOneVariantView {
  display: none; }

.ngx-charts .grid-panel.odd rect {
  fill: none !important; }

ngx-charts-bar-horizontal-2d,
ngx-charts-bar-horizontal-stacked {
  z-index: 0; }

.ngx-charts-guide-line-title {
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 0 0 0; }

.ngx-charts-guide-line-title.left {
  float: left; }

.ngx-charts-guide-line-title.right {
  float: right;
  margin-right: 15px; }

.ngx-charts-guide-line-title.right.word-break {
  margin-right: 0; }

.graph-with-guide-line {
  position: relative;
  width: 100%;
  min-height: 100px;
  margin-top: -20px; }
  .graph-with-guide-line .ngx-charts-guide-line-label {
    position: absolute;
    width: 60px; }
    .graph-with-guide-line .ngx-charts-guide-line-label label {
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      margin: 0; }
  .graph-with-guide-line ngx-charts-bar-horizontal-2d {
    width: 40%;
    margin-left: 40px; }
  .graph-with-guide-line .ngx-charts-guide-line {
    width: 90%;
    height: 150px;
    position: absolute;
    right: 20px;
    top: 10px;
    border-bottom: 1px solid #bababa;
    border-left: 1px solid #bababa;
    z-index: 0; }
    .graph-with-guide-line .ngx-charts-guide-line-empty-div {
      width: 45%;
      display: inline-block;
      min-height: 10px; }
    .graph-with-guide-line .ngx-charts-guide-line-label-div {
      display: inline-block;
      width: 35%;
      height: 148px;
      border-left: 1px dashed #bababa;
      border-right: 1px dashed #bababa;
      text-align: left;
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 3px; }
      .graph-with-guide-line .ngx-charts-guide-line-label-div p {
        font-size: 1rem;
        line-height: 13px;
        margin: 4.5px 0; }
    .graph-with-guide-line .ngx-charts-guide-line-count-div {
      display: inline-block;
      width: 5%; }
      .graph-with-guide-line .ngx-charts-guide-line-count-div span {
        font-size: 1rem;
        position: absolute;
        top: 45%;
        right: 5px; }

#geneChartDiv1 .ngx-charts-guide-line-label label {
  line-height: 280px; }

#geneChartDiv1 .ngx-charts-guide-line {
  height: 270.5px; }

#geneChartDiv1 .ngx-charts-guide-line-label-div {
  height: 268px; }
  #geneChartDiv1 .ngx-charts-guide-line-label-div p {
    font-size: 1rem;
    line-height: 15px;
    margin-bottom: 5px; }

#geneChartDiv2 .ngx-charts-guide-line-label label {
  line-height: 160px; }

#geneChartDiv3 .ngx-charts-guide-line-label label {
  line-height: 160px; }

#geneChartDiv4 .ngx-charts-guide-line-label label {
  line-height: 180px; }

#geneChartDiv4 .ngx-charts-guide-line {
  height: 170px; }

#geneChartDiv4 .ngx-charts-guide-line-label-div {
  height: 168px; }

#geneChartDiv5 .ngx-charts-guide-line-label label {
  line-height: 145px; }

#geneChartDiv5 .ngx-charts-guide-line {
  height: 135px; }

#geneChartDiv5 .ngx-charts-guide-line-label-div {
  height: 133px; }

.graph-container.graph-with-guide-line.variantChartDiv {
  margin: 45px 0 20px 0; }
  .graph-container.graph-with-guide-line.variantChartDiv ngx-charts-bar-horizontal-stacked {
    width: 70%; }
  .graph-container.graph-with-guide-line.variantChartDiv ngx-charts-bar-horizontal-stacked.active {
    margin-left: 151px !important; }
  .graph-container.graph-with-guide-line.variantChartDiv ngx-charts-bar-horizontal-stacked.none {
    margin-left: 122px !important; }
  .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line-label {
    position: absolute;
    width: 141px;
    height: 200px; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line-label label {
      font-size: 1rem;
      font-weight: 400;
      width: 126px;
      margin-top: 10px;
      margin-bottom: 15px; }
  .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line {
    position: absolute;
    top: -25px;
    right: 2px;
    width: 85%;
    height: 285px;
    border-left: 1px solid #bababa;
    z-index: 0;
    text-align: left; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container:nth-child(1)
.ngx-stacked-charts-guide-line-label-div {
      top: 20px; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container:nth-child(2)
.ngx-stacked-charts-guide-line-label-div {
      top: 70px; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container:nth-child(3)
.ngx-stacked-charts-guide-line-label-div {
      top: 120px; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container:nth-child(4)
.ngx-stacked-charts-guide-line-label-div {
      top: 170px; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container:nth-child(5)
.ngx-stacked-charts-guide-line-label-div {
      top: 220px; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container {
      display: inline-block;
      width: 85%;
      height: 50px; }
      .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container .ngx-stacked-charts-guide-line-label-div {
        position: absolute;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line .ngx-stacked-charts-guide-line-container .ngx-stacked-charts-guide-line-label-div label {
          font-size: 1rem;
          font-weight: 400; }
    .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line-count-div {
      position: absolute;
      top: 0;
      right: 0;
      width: 15%;
      height: 285px;
      border-left: 1px solid #bababa;
      text-align: right;
      padding-right: 50px; }
      .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line-count-div label {
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        padding: 16px 0; }
      .graph-container.graph-with-guide-line.variantChartDiv .ngx-stacked-charts-guide-line-count-div label.word-break {
        padding: 8.5px 0; }

.graph-container.graph-with-guide-line.variantChartDiv.snv .ngx-stacked-charts-guide-line-label label {
  height: 28px;
  text-align: left;
  padding-left: 20px; }

.graph-container.graph-with-guide-line.variantChartDiv.cnv .ngx-stacked-charts-guide-line-label label {
  text-align: left;
  padding-left: 30px; }

.graph-container.graph-with-guide-line.variantChartDiv.sv .ngx-stacked-charts-guide-line-label label {
  text-align: left;
  padding-left: 10px; }

.graph-container.graph-with-guide-line.variantChartDiv.cnv .ngx-stacked-charts-guide-line-label label,
.graph-container.graph-with-guide-line.variantChartDiv.sv .ngx-stacked-charts-guide-line-label label {
  line-height: 26px; }

.ngx-charts-guide-line-title.gene.left {
  padding-left: 55px; }

.ngx-charts-guide-line-title.gene.right {
  padding-right: 5px; }

.ngx-charts-guide-line-title.variant.left {
  padding-left: 150px; }

.ngx-stacked-charts-percent-label {
  display: block;
  margin-bottom: 0;
  color: #000; }

.pie-chart-none > div {
  width: 20%;
  margin: 50px auto;
  background: #e9e9e9; }
  .pie-chart-none > div label {
    padding: 20px 70px;
    font-weight: 400;
    margin: 0; }

.stacked-bar-chart-none {
  height: 250px; }

.stacked-bar-chart-none > div {
  width: 20%;
  margin: 80px auto;
  background: #e9e9e9; }
  .stacked-bar-chart-none > div label {
    padding: 20px 0;
    font-weight: 400;
    margin: 0; }

.quick-search > p {
  font-size: 1.2rem;
  text-align: center;
  margin: 0; }

.quick-search ul {
  list-style: none;
  padding: 10px 30px;
  margin: 0;
  text-align: center; }
  .quick-search ul li {
    font-size: 1.2rem;
    text-align: left;
    margin: 5px 0;
    padding: 5px 20px;
    background: #e9e9e9;
    border-radius: 7px; }
    .quick-search ul li p {
      word-break: break-all; }
  .quick-search ul li:hover,
  .quick-search ul li.active {
    background: #09b894;
    color: #fff;
    cursor: pointer; }

label.linked {
  z-index: 1;
  cursor: pointer;
  text-decoration: underline;
  color: #008cba; }

a.linked {
  cursor: pointer;
  text-decoration: underline; }

.view-indicator {
  width: 6.5%;
  position: fixed;
  right: 8px;
  bottom: 10px;
  z-index: 1;
  text-align: center; }
  .view-indicator ul {
    list-style: none;
    padding: 10px 0;
    margin: 0; }
    .view-indicator ul li {
      width: 100%;
      padding: 5px 14px;
      margin: 10px 0;
      box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.34);
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      font-size: 1rem; }
    .view-indicator ul li:hover {
      color: white;
      background: #09b894;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.2); }

.red-text {
  color: red; }

.blue-text {
  color: blue; }

.detail-view-link-select-box {
  margin-right: 10px; }

.common-all-btn {
  float: left;
  font-size: 1.2rem;
  padding: 0px 6px;
  border: 1px solid #bababa;
  color: #000;
  margin-left: 10px; }

.only-gene-all.only-gene-all {
  float: right;
  margin-top: -2px; }

.common-all-btn:hover,
.only-gene-all:hover {
  background: #09b894;
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

p-table.common-variant-all-data-table .ui-table .ui-table-thead > tr > th,
p-table.detail-view-data-table .ui-table .ui-table-thead > tr > th {
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center; }

p-table.common-variant-all-data-table .ui-table .ui-table-tbody > tr > td,
p-table.detail-view-data-table .ui-table .ui-table-tbody > tr > td {
  overflow: unset;
  white-space: normal;
  text-overflow: unset;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left; }

p-table.common-variant-all-data-table
.ui-table
.ui-table-tbody
> tr
> td.counting-td,
p-table.detail-view-data-table .ui-table .ui-table-tbody > tr > td.counting-td,
p-table.last-data-view-table .ui-table .ui-table-tbody > tr > td.counting-td {
  text-align: right; }

p-table.last-data-view-table > .ui-table .ui-table-thead > tr > th {
  text-align: center;
  word-break: break-all; }

p-table.common-variant-all-data-table .active {
  background: #09b894 !important;
  color: white !important; }

p-dialog.quick-search-all .ui-dialog {
  padding: 0 !important; }
  p-dialog.quick-search-all .ui-dialog ul {
    padding: 0;
    list-style: none; }
    p-dialog.quick-search-all .ui-dialog ul li {
      display: inline-block;
      font-size: 1.2rem;
      text-align: left;
      padding: 5px 15px;
      background: #e9e9e9;
      border-radius: 7px;
      margin: 5px;
      min-width: 100px;
      word-break: break-all; }
    p-dialog.quick-search-all .ui-dialog ul li:hover {
      background: #09b894;
      color: #fff;
      cursor: pointer; }
    p-dialog.quick-search-all .ui-dialog ul li.quick-variant {
      min-width: 400px; }

p-dialog.quick-search-all .ui-dialog .ui-dialog-content.ui-widget-content {
  overflow-x: hidden; }

.btn.common-btn-small-default.ui-button {
  float: left;
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  background: none !important;
  box-shadow: none !important;
  border: 1px solid #a6a6a6 !important;
  color: #000 !important;
  padding: 1px 6px;
  margin-left: 10px; }
  .btn.common-btn-small-default.ui-button span {
    padding: 0; }

.btn.common-btn-small-default.ui-button:hover {
  border: 1px solid #09b894 !important;
  background: #09b894 !important;
  color: #ffffff !important;
  box-shadow: none !important; }

.text-color-green {
  color: #09b894; }

/*level0 view chart graph common style*/
.mat-option {
  overflow: unset !important; }

#flexLayout {
  flex-direction: column !important; }

.noneGraph {
  display: none !important; }

.all-list-dialog .ui-dialog {
  padding: 0 !important;
  left: 30px !important;
  right: 30px !important; }

.all-list-dialog .ui-dialog-content.ui-widget-content .ui-dropdown {
  width: 115px !important; }

/*data-explorer 페이지의 그리드 레이아웃 css*/
.grid-layout-chart-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 15% 72% 13%;
  grid-template-columns: 15% 72% 13%;
  grid-template-areas: "menu main right";
  margin-bottom: 10px; }

.grid-layout-header {
  margin-top: 10px; }
  .grid-layout-header label {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400; }
  .grid-layout-header .grid-header-left {
    float: left;
    padding-left: calc(15% + 10px);
    margin: 0; }
  .grid-layout-header .grid-header-right {
    float: right;
    width: 13%;
    margin: 0;
    text-align: left;
    padding-left: 10px; }

.grid-layout-header::after {
  content: "";
  display: table;
  clear: both; }

.grid-layout-label {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: menu;
  display: table;
  height: 100%;
  border-right: 1px solid #bababa;
  margin-top: 5px;
  width: 100%; }
  .grid-layout-label label {
    width: 100%;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    word-break: break-all; }

.grid-layout-chart {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: main;
  margin-left: -20px;
  margin-top: 10px; }
  .grid-layout-chart .ngx-stacked-charts-guide-line-container {
    margin-left: 20px; }
    .grid-layout-chart .ngx-stacked-charts-guide-line-container div {
      position: absolute; }
    .grid-layout-chart .ngx-stacked-charts-guide-line-container label {
      font-size: 1rem;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .grid-layout-chart .ngx-stacked-charts-guide-line-container label.ngx-stacked-charts-percent-label {
      margin-top: -6px; }

.grid-layout-counting {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  display: table;
  height: 100%;
  grid-area: right;
  border-left: 1px solid #bababa;
  margin-top: 5px; }
  .grid-layout-counting label {
    display: table-cell;
    vertical-align: middle;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin: 0; }

/*data-explorer 페이지의 그리드 레이아웃 css*/
.completer-limit /deep/ .completer-dropdown {
  overflow-y: auto;
  max-height: 20rem; }

/*
grid-row*/
.grid-row-1 {
  grid-row: 1;
  -ms-grid-row: 1; }

.grid-row-2 {
  grid-row: 2;
  -ms-grid-row: 2; }

.grid-row-3 {
  grid-row: 3;
  -ms-grid-row: 3; }

.grid-row-4 {
  grid-row: 4;
  -ms-grid-row: 4; }

.grid-row-5 {
  grid-row: 5;
  -ms-grid-row: 5; }

.top-nav {
  padding: 0;
  margin: 0px;
  list-style: none;
  display: flex; }
  .top-nav li {
    padding: 0; }
    .top-nav li a {
      width: 100%;
      text-decoration: none; }

