$kmp-red: #990033;
$kmp-dark: #343a40;

.row {
  margin: 0;
}
[class*="col-"] {
  padding: 0;
}

.registration {
  text-align: center;
  width: 650px;
  margin: 0 auto;
  padding-bottom: 40px;
  &-certified {
    text-align: center;
    width: 600px;
    margin: 0 auto;
    padding-top: 66px;
  }
  .input-required {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 15px;
    text-align: left;
    margin-bottom: 20px;
  }
  .input-lg {
    margin-bottom: 15px;
  }
  #registrationRepwd {
    margin-bottom: 10px;
  }
  &-btn {
    margin-top: 20px;
    .common-btn-default {
      padding: 15px 72px 10px 72px;
      margin-right: 13px;
    }
    .common-btn-green {
      padding: 15px 69px 10px 69px;
    }
  }
  .signin-link {
    text-align: left;
    margin-top: 15px;
    a {
      font-family: "Roboto", "Arial", "Helvetica", sans-serif;
      font-size: 15px;
      color: #333;
      text-decoration: none;
    }
    svg {
      color: #09b894;
      margin-right: 3px;
    }
  }
}
.backButton {
  width: 220px;
  height: 45px;
  border: solid 1px #979797 !important;
  border-radius: 0px !important;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  color: #979797 !important;
  font-size: 14px !important;
}
.backcolor {
  background-color: #c7c7c7;
  border-top: 1px solid #c6c6c6;
  // padding: 52px 209px;
}
.nextButton {
  width: 220px;
  height: 45px;
  margin-left: 10px !important;
  background-color: #26a1a4 !important;
  border-radius: 0px !important;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  color: #ffffff !important;
  font-size: 14px !important;

  &-login {
    width: 451px;
    height: 45px;
    background-color: #26a1a4 !important;
    margin-top: 9px;
    border-radius: 0px !important;
    font-family: AppleSDGothicNeo !important;
    color: #ffffff !important;
    font-size: 14px !important;
  }
}
.disableNextButton{
  width: 220px;
  height: 45px;
  margin-left: 10px !important;
  background-color: #c6c6c6 !important;
  border-radius: 0px !important;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  color: #ffffff !important;
  font-size: 14px !important;

  &-login {
    width: 451px;
    height: 45px;
    background-color: #26a1a4 !important;
    margin-top: 9px;
    border-radius: 0px !important;
    font-family: AppleSDGothicNeo !important;
    color: #ffffff !important;
    font-size: 14px !important;
  }
}
.warming {
  font-size: 15px;
  // text-align: center;
  color: #ff4343;
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  margin: 5px 0px 0px 100px;
  float: left;
  display: flex;
}
.ChevronRight {
  float: right;
}
.link {
  text-decoration: none !important;
}
.backgroundimage {
  width: 100%;
  height: 338px;
  border: solid 1px #979797;
  background-color: rgba(64, 64, 64, 0.5);
  background-image: url("../../assets/images/join/joincertified.jpg");
  margin-bottom: 10px;
}
.stepper {
  padding: 60px 0px 0px 0px !important;
  &-label {
    font-size: 35px !important;
    color: #26a1a4 !important;
  }
  &-nonlabel {
    font-size: 35px !important;
  }
  &-line {
    // border-top: 6px !important;
    span {
      font-size: 15px !important;
    }
  }
}
.email {
  padding-left: 5px;
  padding-top: 3px;
}
