// common ****************************************************************************
.row {
  margin: 0;
}
.no-margin {
  margin: 0;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}

$kmp-red: #990033;
$kmp-dark: #343a40;
$kmp-smokewhite: #d2d2d2;
$kmp-yellow: #f1c40f;
// common ****************************************************************************
.started {
  display: flex;
  // justify-content: center;
  // margin-left: -405px;
  padding-left: 6px; 
  &-case {
    display: flex;
    // justify-content: center;
    // margin-left: -415px;
  }
}
// .stated-icon {
//   margin-top: 30px;
// }
.background {
  display: table;
  width: 0;
  // height: 547px;
  // background-color: #424242;
}
.image {
  font-size: 18px !important;
  color: white;
  margin-right: 5px;
  &-close {
    float: right;
    padding-top: 0px;
  }
}
.close {
  color: white !important;
  float: right;
  padding-top: 0px;
}
.banner {
  width: 100%;
  height: 60px;
  color: #ffffff;
  background-color: #383838;
  // position: absolute;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  &-kmaster {
    font-size: 18px;
  }
  &-else {
    font-size: 15px;
  }
}
.get-started {
  width: 100%;
  height: 100%;
  // min-height: 298px;
  text-align: center;
  color: white;
  background-image: url("../../assets/images/home/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  &-chart {
  }
  &-chart-position {
    display: inline-block;
    padding: 10px 12vw 20px 14.5vw;
    margin: auto;
  }
  &-chart-table {
    display: inline-block;
    padding: 10px 12vw 20px 12vw;
    margin: auto;
  }
  &-chart-center {
    width: 1010px;
  }

  &-chart-centers {
    width: 1060px;
  }
  &-release {
    display: inline-flex;
  }
  &-title {
    display: table-cell;
    vertical-align: middle;
    //p {
    //  margin : 0;
    //}
  }
  &-case {
    display: flex;
    //margin-top: 60px;
    padding-top: 30px;
    padding-left: 50px;
    font-size: 15px;
    // font-weight: 500;
    color: #ffffff;
  }
  &-image {
    display: flex;
    //margin-top: 60px;
    padding-top: 30px;
    // padding-left: 30px;
    font-size: 15px;
    // font-weight: 500;
    color: #ffffff;
  }
  &-title-regular {
    font-weight: 300;
    letter-spacing: 1px;
  }
  &-title-bold {
    margin-top: 0px;
    // padding-top: 30px;
    // padding-left: 30px;
    font-size: 15px;
    // font-weight: 500;
    color: #ffffff;
    display: inline-block;
  }
  &-title-regular {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #e0e0e0;
  }
  &-btn {
    .common-btn-default {
      padding: 15px 68px 10px 68px;
      border: 1px solid white;
      color: white;
    }
    .common-btn-green {
      margin-left: 10px;
      padding: 15px 100px 10px 100px;
      border: 1px solid #09b894 !important;
      background: #09b894 !important;
      color: #fff !important;
    }
  }
}

.home-summary-title {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 70px;
  &-bold {
    font-family: "Open Sans", sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #2c2c2c;
    margin-bottom: 5px;
  }
  &-small {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #151515;
  }
}
.home-bottom {
  height: 58px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  line-height: 4;
  &-sponsor {
    width: 50%;
    background-color: #33312c;
  }
  &-app {
    width: 50%;
    background-color: rgba(78, 78, 78, 0.7);
  }
  &-arrow {
    position: absolute;
    width: 100%;
    font-size: 20px;
  }
}

.home-summary-content {
  text-align: center;
  margin-bottom: 70px;
  .home-summary-card {
    display: inline-block;
    // width: 80vw;
    min-height: 218px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin-right: 40px;
    &-title {
      background: #09b894;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 100;
      color: white;
      border-radius: 16px;
      padding: 6px 0 2px 0;
      margin-bottom: 20px;
    }
    &-content {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      color: #676767;
      margin-top: 15px;
    }
  }
}
.icon-bottom {
  display: flex;
  &-sponser {
    width: 50%;
    text-align: center;
  }
  &-family-site {
    padding: 20px;
  }
  &-app {
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
  }
  &-appitem {
    font-size: 12px;
    padding: 7px 10px 0px 10px;
    height: 50px;
    
  }
}
.test {
  height: 240px;
}
.MainTitltle {
  font-size: 33px;
  padding-top: 80px 
}
.subTitltle{
  font-size: 18px;
  padding-top: 15px;
  &-btn {
    padding-top: 3px;
  }
}