.forgot-password-content {
  width: 457px;
  margin: 130px 20px;
  .forgot-password-btn {
    margin-top: 20px;
    .common-btn-default {
      padding: 15px 74px 10px 74px;
      margin-right: 13px;
    }
    .common-btn-green {
      padding: 15px 88px 10px 88px;
    }
  }
}
.form-control{
  height:30px;
  width:100%;
  font-size: 14px;
  
}